import TagManager from "react-gtm-module";

function ButtonClick({ buttonName }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "toggle_change",
      buttonName: buttonName
    }
  };
  TagManager.dataLayer(tagManagerArgs);
}

export default ButtonClick;
