import PropTypes from "prop-types";

function CollegePrivate({
  size = 25,
  iconColor = "fill-black",
  fillColor = "fill-white",
  strokeColor = "stroke-black"
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 50 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="2"
        strokeMiterlimit="10"
        className={`${fillColor} ${strokeColor}`}
        d="M20.0372 43.5542V58.5886H29.652V43.5542C29.199 42.4799 28.5105 41.5782 27.6622 40.9486C26.8139 40.3189 25.8389 39.9857 24.8446 39.9857C23.8503 39.9857 22.8753 40.3189 22.027 40.9486C21.1788 41.5782 20.4902 42.4799 20.0372 43.5542V43.5542ZM1 10.2179V58.5886H48.6091V10.2179C41.6547 4.22154 33.3353 1 24.8045 1C16.2738 1 7.95447 4.22154 1 10.2179Z"
      />
      <path
        className={iconColor}
        d="M12.6971 13.6997H7.61719V19.8652H12.6971V13.6997Z"
      />
      <path
        className={iconColor}
        d="M27.3611 13.6997H22.2812V19.8652H27.3611V13.6997Z"
      />
      <path
        className={iconColor}
        d="M42.0056 13.6997H36.9258V19.8652H42.0056V13.6997Z"
      />
      <path
        className={iconColor}
        d="M12.6971 26.0112H7.61719V32.1767H12.6971V26.0112Z"
      />
      <path
        className={iconColor}
        d="M27.3611 26.0112H22.2812V32.1767H27.3611V26.0112Z"
      />
      <path
        className={iconColor}
        d="M42.0056 26.0112H36.9258V32.1767H42.0056V26.0112Z"
      />
    </svg>
  );
}

CollegePrivate.propTypes = {
  size: PropTypes.number,
  iconColor: PropTypes.string,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string
};

export default CollegePrivate;
