import { Routes, Route } from "react-router-dom";

import NotFound from "./NotFound";

export default function CustomSwitch({ children }) {
  return (
    <Routes>
      {children}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
