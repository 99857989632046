import PropTypes from "prop-types";

function CollegeRural({ size = 25, color = "stroke-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 60 63"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="2"
        className={color}
        strokeMiterlimit="10"
        d="M0 62H60M48.6184 23.7391V60.125M34.9605 27.6793H27.9798V37.7065H34.9605V27.6793ZM18.1157 27.6793H11.135V37.7065H18.1157V27.6793ZM26.9934 45.5326H19.5195V62H26.9934V45.5326ZM41.6756 19.1467L22.7063 2L4.76132 19.0381L41.6756 19.1467ZM40.803 19.5544H5.57699V62H40.8789L40.803 19.5544ZM48.6184 38.3859C52.4123 38.3859 55.5422 32.462 55.5422 25.1522C55.5422 17.8424 52.4502 11.9185 48.6184 11.9185C44.7866 11.9185 41.6946 17.8424 41.6946 25.1522C41.6946 32.462 44.7866 38.3315 48.5994 38.3315L48.6184 38.3859Z"
      />
    </svg>
  );
}

CollegeRural.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default CollegeRural;
