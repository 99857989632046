import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Colors, getColors } from "../utils";

function PillText({
  color = Colors.DEFAULT,
  value,
  placeholder,
  handlerOnAdd,
  handlerOnPaste,
  handlerOnChange,
  ...restProps
}) {
  const [search, setSearch] = useState("");

  useEffect(() => setSearch(value), [value]);

  const handlerOnLocalBlur = () => handlerOnAdd(search.trim());

  const handlerOnLocalChange = (event) => {
    event.preventDefault();
    setSearch(event.currentTarget.value);
    handlerOnChange(event.currentTarget.value);
  };

  const handlerOnLocalAdd = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.preventDefault();
      const computedSearch = search.trim().replace(/,/gi, "");
      handlerOnAdd(computedSearch);
    }
  };

  const handlerOnLocalPaste = (event) => {
    event.preventDefault();
    handlerOnPaste(event.clipboardData.getData("Text"));
  };

  const colors = getColors(color);

  return (
    <div className="px-1 h-full">
      <div
        className="
          p-2
          flex
          flex-1
          flex-row
          border-b
          bg-white
          items-center
          border-black
          border-opacity-50
          focus-within:border-opacity-100
        "
      >
        <input
          type="text"
          value={search}
          aria-label="searchInput"
          placeholder={placeholder}
          onBlur={handlerOnLocalBlur}
          onKeyDown={handlerOnLocalAdd}
          onPaste={handlerOnLocalPaste}
          onChange={handlerOnLocalChange}
          className={`
            flex
            flex-1
            text-sm
            font-medium
            outline-none
            ${colors.primary.text}
          `}
          {...restProps}
        />
      </div>
    </div>
  );
}

PillText.propTypes = {
  placeholder: PropTypes.string,
  searchEndpoint: PropTypes.func,
  onSelectResult: PropTypes.func,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default PillText;
