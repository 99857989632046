const SET_ERROR = "USER/SET_ERROR";
const SET_PROFILE = "USER/SET_PROFILE";
const SET_ACTIVE_TAB = "USER/SET_ACTIVE_TAB";
const SET_IS_LOADING = "USER/SET_IS_LOADING";
const SET_IS_LOGGED_IN = "USER/SET_IS_LOGGED_IN";
const SET_SCHEMA_TYPES = "USER/SET_SCHEMA_TYPES";
const SET_CURRENT_ORGANIZATION_ID = "USER/SET_CURRENT_ORGANIZATION_ID";
const SET_HAS_ORGANIZATIONS = "USER/SET_HAS_ORGANIZATIONS";

const Types = {
  SET_ERROR,
  SET_PROFILE,
  SET_ACTIVE_TAB,
  SET_IS_LOADING,
  SET_IS_LOGGED_IN,
  SET_SCHEMA_TYPES,
  SET_CURRENT_ORGANIZATION_ID,
  SET_HAS_ORGANIZATIONS
};

export default Types;
