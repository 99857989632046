import PropTypes from "prop-types";

import { Colors, getColors } from "../utils";

function Toggle({
  name,
  color = Colors.DEFAULT,
  label,
  value,
  checked,
  disabled,
  required,
  labelUpper,
  ...restProps
}) {
  const getComposeBorder = (border) => {
    switch (border) {
      case "border-primary-red":
        return "hover:border-primary-red";
      case "border-primary-blue":
        return "hover:border-primary-blue";
      case "border-primary-green":
        return "hover:border-primary-green";
      case "border-primary-orange":
        return "hover:border-primary-orange";

      default:
        return "hover:border-black";
    }
  };

  const colors = getColors(color);
  const border = getComposeBorder(colors.primary.border);
  const computeBg =
    colors.primary.bg === "bg-white" ? "bg-black" : colors.primary.bg;

  return (
    <label
      className={`
        py-2
        flex
        flex-1
        flex-row
        items-center
        ${disabled ? "opacity-40 cursor-not-allowed" : "cursor-pointer"}
      `}
    >
      <div
        className={`
          h-6
          w-11
          mr-2
          flex
          border
          relative
          rounded-full
          items-center
          ${!disabled ? border : ""}
          ${value ? computeBg : "bg-white"}
          ${value ? colors.primary.border : "border-black"}
        `}
      >
        <input
          id={name}
          name={name}
          value={value}
          type="checkbox"
          aria-label={name}
          checked={checked}
          className={`
            sr-only
            text-sm
            font-medium
            outline-none
            ${disabled ? "pointer-events-none" : ""}
          `}
          {...restProps}
        />
        <div
          style={{ marginTop: 1 }}
          className={`
            h-4
            w-4
            left-1
            top-0.5
            absolute
            shadow-sm
            transition
            rounded-full
            ${!value ? computeBg : "bg-white"}
            ${value ? "translate-x-full" : "translate-x-0"}
          `}
        ></div>
      </div>
      <span
        className={`
          ml-2
          flex
          text-sm
          flex-row
          capitalize
          font-semibold
          ${labelUpper ? "uppercase" : "capitalize"}
        `}
      >
        {label}
        {required && (
          <div className={`px-1 font-bold ${colors.primary.text}`}>*</div>
        )}
      </span>
    </label>
  );
}

Toggle.propTypes = {
  value: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  labelUpper: PropTypes.bool,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default Toggle;
