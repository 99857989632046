import TagManager from "react-gtm-module";

function ToggleChange({ toggleId, toggleState }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "toggle_change",
      toggleName: toggleId,
      toggleState: toggleState ? "on" : "off"
    }
  };
  TagManager.dataLayer(tagManagerArgs);
}

export default ToggleChange;
