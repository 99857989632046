import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Api from "../services/Api";
import { useUser } from "../redux/User/hooks";

export function useAuthentication() {
  const [token, setToken] = useState(null);
  const [ready, setReady] = useState(false);
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    getIdTokenClaims,
    logout
  } = useAuth0();

  useEffect(() => {
    (async () => {
      setReady(false);
      setToken(null);
      if (!isLoading) {
        if (isAuthenticated) {
          const getToken = async () => {
            await getAccessTokenSilently();
            const claims = await getIdTokenClaims();
            return claims.__raw;
          };
          Api.configure({
            token: getToken
          });
          const token = await getToken();
          setToken(token);
          setReady(true);
        } else {
          return await loginWithRedirect();
        }
      }
    })();
  }, [
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    getIdTokenClaims
  ]);

  return { isAuthenticated: ready, token, logout };
}

export function useLogin() {
  const auth0 = useAuth0();
  const user = useUser();
  const { isAuthenticated, logout } = useAuthentication();

  const [error, setError] = useState(null);
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [advisorUserExists, setAdvisorUserExists] = useState(false);
  const [mainAppUserExists, setMainAppUserExists] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        if (isAuthenticated) {
          const claims = await auth0.getIdTokenClaims();
          setProfile(claims);
          if (!user.isLoggedIn) {
            const advisorExists = await Api.checkUserInAdvisor();
            setAdvisorUserExists(advisorExists);
            const mainAppExists = await Api.checkUserInMainApp();
            setMainAppUserExists(mainAppExists);
          } else {
            setMainAppUserExists(true);
          }
          setIsLoading(false);
        }
      } catch (e) {
        setError(e);
      }
    })();
  }, [isAuthenticated, auth0, user.isLoggedIn]);

  return {
    isLoading,
    advisorUserExists,
    mainAppUserExists,
    auth0,
    profile,
    error,
    logout
  };
}

export function forceUserToSignIn() {
  window.location.href = "/force-login";
}
