import Types from "./types";

const INITIAL_STATE = {
  error: null,
  isLoading: false,
  organization: null
};

export const organizationReducer = (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case Types.RESET:
      return { ...INITIAL_STATE };
    case Types.SET_ERROR:
      return {
        ...state,
        error: action.error
      };
    case Types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case Types.SET_ORGANIZATION:
      return {
        ...state,
        organization: action.organization
      };
    default:
      return state;
  }
};
