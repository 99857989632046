import PropTypes from "prop-types";

function ArrowDown({ size = 16, className = "" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-current ${className}`}
    >
      <path d="M3.5,5.5l5,5l5-5" strokeWidth="2" strokeLinejoin="round" />
    </svg>
  );
}

ArrowDown.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string
};

export default ArrowDown;
