import PropTypes from "prop-types";

import { components } from "react-select";
import AsyncSelect from "react-select/async";

import { Colors, getColors } from "../utils";

function Select({
  id,
  color = Colors.DEFAULT,
  multi,
  label,
  value,
  required,
  disabled,
  onChange = () => {},
  subLabel,
  checkbox,
  loadOptions = () => {},
  placeholder,
  defaultValue,
  closeOnSelect,
  maxHeight
}) {
  const colors = getColors(color);

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className="flex flex-1 flex-row space-x-2">
          <input
            type="checkbox"
            onChange={() => null}
            checked={props.isSelected}
          />
          <label>{props.label}</label>
        </div>
      </components.Option>
    );
  };

  return (
    <div className={`py-2 ${disabled ? "cursor-not-allowed" : ""}`}>
      <div className={`mb-2 text-sm ${disabled && "opacity-40"}`}>
        {label && (
          <span
            className={`
              flex
              flex-row
              font-semibold
              ${colors.primary.text}
            `}
          >
            {label}
            {required && <div className="px-1 font-bold">*</div>}
          </span>
        )}
        {subLabel && <span className="font-medium text-black">{subLabel}</span>}
      </div>
      <AsyncSelect
        id={id}
        cacheOptions
        value={value}
        defaultOptions
        isMulti={multi}
        onChange={onChange}
        isDisabled={disabled}
        placeholder={placeholder}
        loadOptions={loadOptions}
        defaultValue={defaultValue}
        menuShouldScrollIntoView={true}
        components={checkbox ? { Option } : null}
        hideSelectedOptions={checkbox ? false : null}
        closeMenuOnSelect={checkbox ? false : closeOnSelect}
        styles={{
          control: (base, state) => ({
            ...base,
            borderRadius: 0,
            fontSize: "14px",
            padding: "3px 2px",
            cursor: state.isDisabled ? "not-allowed" : "default",
            boxShadow: state.isFocused ? "0 1px 6px 0 #000000" : "none",
            border: state.isDisabled
              ? "1px solid rgba(0, 0, 0, .5)"
              : "1px solid #000000",
            "&:hover": { border: "1px solid #000000" }
          }),
          valueContainer: (base) => ({
            ...base,
            maxHeight: 90,
            fontWeight: 500,
            overflow: "auto"
          }),
          dropdownIndicator: (base, state) => ({
            ...base,
            color: "#000000",
            cursor: "pointer",
            opacity: state.isDisabled ? 0.5 : 1,
            "&:hover": { color: colors.primary.hexa }
          }),
          clearIndicator: (base) => ({
            ...base,
            color: "#000000",
            cursor: "pointer",
            "&:hover": { color: colors.primary.hexa }
          }),
          indicatorSeparator: (base, state) => ({
            ...base,
            border: "1px solid #000000",
            opacity: state.isDisabled ? 0.5 : 1
          }),
          menu: (base) => ({
            ...base,
            borderTop: 0,
            marginTop: 0,
            maxHeight: maxHeight || 200,
            borderRadius: 0,
            padding: "0 5px",
            fontSize: "14px",
            minHeight: "fit-content",
            borderLeft: "1px solid #000000",
            borderRight: "1px solid #000000",
            boxShadow: "0 1px 6px 0 #000000",
            borderBottom: "1px solid #000000"
          }),
          menuList: (base) => ({
            ...base,
            maxHeight: maxHeight || 200
          }),
          option: (base, state) => ({
            ...base,
            color: state.isSelected ? "#FFFFFF" : "#000000",
            backgroundColor: state.isSelected
              ? colors.primary.hexa
              : state.isFocused
              ? colors.secondary.hexa
              : "transparent",
            "&:hover": {
              color: "#000000",
              backgroundColor: colors.secondary.hexa
            },
            "&:active": {
              color: "#000000",
              backgroundColor: colors.secondary.hexa
            }
          }),
          multiValue: (base) => ({
            ...base,
            fontWeight: 500,
            borderRadius: 5,
            backgroundColor: colors.secondary.hexa
          }),
          multiValueRemove: (base) => ({
            ...base,
            color: "#000000",
            "&:hover": { color: "#000000", backgroundColor: "transparent" }
          })
        }}
      />
    </div>
  );
}

Select.propTypes = {
  id: PropTypes.string,
  multi: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  checkbox: PropTypes.bool,
  disabled: PropTypes.bool,
  subLabel: PropTypes.string,
  loadOptions: PropTypes.func,
  closeOnSelect: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape())
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape())
  ]),
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default Select;
