import PropTypes from "prop-types";

function CollegeSuburban({ size = 25, color = "stroke-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 62 63"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="2"
        className={color}
        strokeMiterlimit="10"
        d="M12.1783 52.6672H26.2725M12.1783 43.8256H21.1319M55.7129 19.2902H35.4727M56.3199 62V17.7921M0 62H60M49.7186 61.9509H44.1985V41.8117H49.7186V61.9509ZM31.3753 35.1559H11.761V61.9263H31.3753V35.1559ZM23.3512 10.1294L24.3946 9.36799L34.2017 2L57.1167 19.6586L23.3512 10.1294ZM36.7436 20.7393L21.5681 8.82767L4.83716 23.1707L36.7436 20.7393ZM37.5592 23.6374H5.57699V62H37.5592V23.6374Z"
      />
    </svg>
  );
}

CollegeSuburban.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default CollegeSuburban;
