import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Button, LoadingButton } from "../../core/buttons";

import Api from "../../services/Api";
import { useUser } from "../../redux/User/hooks";
import { fetchUser, setOrganizationId } from "../../redux/User/actions";
import { saveState, Items } from "../../redux/storePersist";

function Invitation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { invitationId } = useParams();

  const user = useUser();

  const [error, setError] = useState();
  const [isReady, setIsReady] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [invitation, setInvitation] = useState({});
  const [errorMutation, setErrorMutation] = useState();
  const [isLoadingMutation, setIsLoadingMutation] = useState(false);

  const skipOnClick = () => navigate("/");

  const acceptOnClick = async () => {
    setErrorMutation();
    setIsLoadingMutation(true);
    try {
      await Api.acceptInvitation({ invitationId });

      const organizationId = invitation.organization_id;
      dispatch(setOrganizationId(`${organizationId}`));
      dispatch(fetchUser());

      saveState({ internalState: {} }, Items.APP_STATE);
      navigate("/");
    } catch (error) {
      setErrorMutation(error);
      setIsLoadingMutation(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (invitationId) {
        try {
          const allInvitations = await Api.myPendingInvitations();
          const invitation = allInvitations.find(
            (inv) => inv.id === invitationId
          );

          if (!invitation) {
            setError({ errors: { message: "Invitation not found!" } });
            setIsReady(true);
            return;
          }

          if (user && user.isLoggedIn) {
            if (invitation.status === "ACCEPTED") {
              setAccepted(true);
            }
            setInvitation(invitation);
            setIsReady(true);
          } else {
            if (invitation.expired) {
              setError({ errors: { message: "Invitation has expired!" } });
              setIsReady(true);
            }
          }
        } catch (error) {
          setError(error);
          setIsReady(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isReady)
    return <div className="w-full font-bold text-center">Loading...</div>;
  else
    return (
      <div className="flex flex-1 flex-row bg-primary-blue justify-center items-center p-4">
        <div
          className="
            flex
            flex-1
            flex-col
            p-4
            shadow-lg
            relative
            overflow-hidden
            max-w-3xl
            bg-white"
        >
          <div className="flex flex-1 flex-row">
            <p
              className="
                  mb-8
                  text-3xl
                  font-bold
                  leading-none
                  tablet:px-6
                  tablet:py-10
                  tablet-sm:px-6
                  tablet-sm:py-10
                  laptop:text-4xl
                  desktop:text-5xl
                "
            >
              Join us in DecidED
            </p>
          </div>
          <div className="flex flex-1 flex-col">
            <div className="my-12">
              <div className="text-4xl text-primary-blue font-extrabold">
                {error ? error.errors?.message : invitation?.subject}
              </div>
              <div className="text-2xl text-black my-12 mb-4">
                {invitation?.body}
              </div>
            </div>

            {errorMutation && (
              <p className="pb-2 text-primary-red">
                {errorMutation.errors[0].message}
              </p>
            )}

            <div
              className={`
                  flex
                  flex-row
                  flex-1
                  space-x-4
                    ${(error || accepted) && "hidden"}
                `}
            >
              <div className="w-1/2">
                <Button
                  className="
                      w-full
                      bg-white
                      text-primary-blue
                      border-primary-blue
                      hover:text-white
                      active:text-white
                      hover:bg-primary-blue
                      active:bg-primary-blue
                    "
                  onClick={() => skipOnClick()}
                >
                  Skip
                </Button>
              </div>
              <div className="w-1/2">
                <LoadingButton
                  isLoading={isLoadingMutation}
                  onClick={() => acceptOnClick()}
                  id="delete-group-save-button"
                  className="
                      w-full
                      text-white
                      bg-primary-blue
                      border-primary-blue
                      hover:bg-white
                      active:bg-white
                      hover:text-primary-blue
                      active:text-primary-blue
                    "
                >
                  Accept
                </LoadingButton>
              </div>
            </div>
            <div
              className={`
                  flex
                  flex-1
                  flex-row
                  ${!accepted && !error && "hidden"}
                `}
            >
              <div className="w-full">
                <Button
                  onClick={() => skipOnClick()}
                  className="
                      mb-5
                      w-full
                      text-white
                      bg-primary-blue
                      border-primary-blue
                      hover:bg-white
                      active:bg-white
                      hover:text-primary-blue
                      active:text-primary-blue
                    "
                >
                  Return
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Invitation;
