import Types from "./types";

const INITIAL_STATE = {
  error: null,
  activeTab: 0,
  profile: null,
  isLoading: false,
  isLoggedIn: false,
  schemaTypes: null,
  organizationId: null,
  hasOrganizations: false
};

export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case Types.SET_ERROR:
      return {
        ...state,
        error: action.error
      };
    case Types.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab
      };
    case Types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case Types.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn
      };
    case Types.SET_CURRENT_ORGANIZATION_ID:
      return {
        ...state,
        organizationId: action.organizationId
      };
    case Types.SET_PROFILE:
      return {
        ...state,
        profile: action.profile
      };
    case Types.SET_SCHEMA_TYPES:
      return {
        ...state,
        schemaTypes: action.schemaTypes
      };
    case Types.SET_HAS_ORGANIZATIONS:
      return {
        ...state,
        hasOrganizations: action.hasOrganizations
      };
    default:
      return state;
  }
};
