import TagManager from "react-gtm-module";

function PageViewDataLayer({ screenName }) {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: "page_view",
      screen_name: screenName
    }
  };
  TagManager.dataLayer(tagManagerArgs);
}

export default PageViewDataLayer;
