import { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Form } from "../../../core";
import { PillList } from "../../../core/compose";
import { LoadingButton, Button } from "../../../core/buttons";
import { PillText, Select } from "../../../core/inputs";
import { Colors } from "../../../core/utils";

import ModalContext from "../../../contexts/ModalContext";
import Api from "../../../services/Api";

import { fetchUser, setOrganizationId } from "../../../redux/User/actions";
import { GetAccess } from "../../Common/Utils";

function InviteUsersModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalContext = useContext(ModalContext);

  const { orgId, isSelfSignUp = false } = props.data;

  const defaultValues = {
    users: [],
    access: {}
  };

  const extractEmails = (text) => {
    return text.match(/([\w+-.%]+@[\w-.]+\.[a-zA-Z0-9_-]+)/gi) || [];
  };

  const FORM_ID = "invite-additional-users-form";

  const [emails, setEmails] = useState([]);
  const [access, setAccess] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [noAccessSelected, setNoAccessSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    setError,
    handleSubmit,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    defaultValues
  });

  const onSubmit = async (data) => {
    try {
      const response = await Api.inviteUser({
        emails: data.users.map((email) => email.label),
        organizationId: orgId,
        role: access.value
      });

      if (response) {
        await dispatch(fetchUser());
        await dispatch(setOrganizationId({ organizationId: orgId }));
      }
      modalContext.closeModalHandler("inviteUsers");
    } catch (error) {
      if (error.type === "GraphQLError") {
        setErrorMessage(error.errors[0].message);
      }
    }
  };

  const handleOnCancel = () => {
    modalContext.closeModalHandler("inviteUsers");
    navigate("/");
  };

  const handlerOnSelectAccess = (data) => {
    setAccess(data);
    setNoAccessSelected(false);
  };

  const fetchAccessRoles = (search, callback) => {
    const accessRoles = GetAccess().map((access) => ({
      value: access.value,
      label: access.label
    }));

    callback(() =>
      accessRoles.filter((access) =>
        access.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    );
  };

  const handlerOnEmailChange = (value) => {
    clearErrors("users");
    setCurrentEmail(value);
  };

  const handlerOnEmailsPaste = (emailsPasted) => {
    clearErrors("users");
    let localEmails = [];
    let badEmails = emailsPasted;
    let newEmailsPasted = extractEmails(emailsPasted);

    newEmailsPasted.forEach((email) => {
      badEmails = badEmails.replace(email, "\n");
      if (!localEmails.some((emailItem) => emailItem === email))
        localEmails.push(email);
    });
    localEmails = localEmails
      .filter(
        (emailItem) => !emails.map((email) => email.label).includes(emailItem)
      )
      .map((emailItem) => ({ label: emailItem }));
    setEmails((emails) => [...emails, ...localEmails]);
    setCurrentEmail(
      badEmails
        .split("\n")
        .filter((email) => email.length > 2)
        .join(" ")
    );
  };

  const handlerOnEmailAdd = (email) => {
    email = email.trim();
    clearErrors("users");
    const exists = emails.some((emailItem) => emailItem.label === email);
    if (exists) {
      setError("users", {
        message: "This email already exists."
      });
    } else if (email.match(/^[\w+-.%]+@[\w-.]+\.[A-Za-z]{0,4}$/) && !exists) {
      setEmails((emails) => [...emails, { label: email }]);
      setCurrentEmail("");
    } else {
      if (email && !exists)
        setError("users", {
          message: "There is an invalid email."
        });
    }
  };

  const handlerOnRemovePill = (oldEmail) => {
    setEmails((emails) =>
      emails.filter((email) => email.label !== oldEmail.label)
    );
  };

  const isButtonDisabled = access.value == null || emails.length < 1;

  return (
    <div
      className="flex flex-col h-full justify-between"
      id="invite-user-modal"
    >
      <div className="flex flex-col w-full h-full">
        {isSelfSignUp && (
          <p className="font-bold text-lg w-2/3">
            You can invite additional users to your organization now or anytime
            in the future from your organization settings page.
          </p>
        )}
        <div className="p-1 flex flex-1 flex-col overflow-auto justify-self-end">
          <Form
            id={FORM_ID}
            onSubmit={handleSubmit(async (form) => {
              if (emails.length === 0) {
                setError("users", {
                  message: "At least an user's email is required."
                });
                return;
              }
              if (!access.value) {
                setNoAccessSelected(true);
                return;
              }

              setIsLoading(true);
              try {
                const formData = {
                  users: emails,
                  role: access.value
                };
                await onSubmit(formData);
              } finally {
                setIsLoading(false);
              }
            })}
          >
            <div className="my-4 flex flex-col">
              <p
                className="
                  mb-2
                  text-sm
                  capitalize
                  font-semibold
                  text-primary-blue
                "
              >
                Users Emails
              </p>
              <div className="p-2 border border-black focus-within:shadow-black">
                <div className="overflow-auto" style={{ maxHeight: "80px" }}>
                  <PillList
                    data={emails}
                    color={Colors.BLUE}
                    pillWidthFull={true}
                    onRemovePill={handlerOnRemovePill}
                  />
                </div>
                <div className="overflow-hidden">
                  <PillText
                    id="email-input-invite-user"
                    value={currentEmail}
                    handlerOnAdd={handlerOnEmailAdd}
                    handlerOnPaste={handlerOnEmailsPaste}
                    handlerOnChange={handlerOnEmailChange}
                    placeholder="jandoe@email.edu, johndoe@email.edu"
                  />
                </div>
              </div>
            </div>
            <Form.ErrorMessage errors={errors} name="users" />
            <Select
              id="role-select-invite-user"
              label="Role"
              value={access}
              color={Colors.BLUE}
              defaultValue={access}
              loadOptions={fetchAccessRoles}
              onChange={handlerOnSelectAccess}
              placeholder="Choose one"
            />
            {noAccessSelected && (
              <p className="text-primary-red font-bold mt-2">
                You must select an access level.
              </p>
            )}
            <div className="mt-4">
              <p className="text-sm">
                <span className="font-bold">Administrator -</span> can invite
                additional users and manage usage.
              </p>
              <p className="text-sm">
                <span className="font-bold">User -</span> can manage developers
                tools, create new api requests, and download results.
              </p>
            </div>
          </Form>
          <p className="text-sm font-semibold text-primary-red">
            {errorMessage}
          </p>
        </div>
      </div>
      <div className="flex flex-row space-x-2 justify-self-end">
        {isSelfSignUp && (
          <Button
            onClick={handleOnCancel}
            className="
            mb-5
            w-1/2
            ml-auto
            text-primary-blue
            bg-white
            border-primary-blue
            hover:bg-primary-blue
            active:bg-primary-blue
            hover:text-white
            active:text-white
            mobile:w-full
            mobile:text-sm
            mobile-sm:w-full
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
          >
            Skip for Now
          </Button>
        )}
        <LoadingButton
          type="submit"
          value="submit"
          form={FORM_ID}
          isLoading={isLoading}
          id="invite-users-form-send-invitation-button"
          disabled={isButtonDisabled}
          className="
            mb-5
            w-1/2
            ml-auto
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
            mobile:w-full
            mobile:text-sm
            mobile-sm:w-full
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          send invites
        </LoadingButton>
      </div>
    </div>
  );
}

export default InviteUsersModal;
