import PropTypes from "prop-types";

function Logo({ size = 50, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size + 20}
      viewBox="0 0 206 225"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M185.269 64.712L181.794 60.741L156.197 31.4946L155.093 30.171C138.901 10.4422 114.858 0 85.5811 0H2.57604L36.7799 38.5541H115.655C141.15 38.5541 162.678 46.9582 177.97 62.5899C178.096 62.7059 178.213 62.8324 178.318 62.9681L178.931 63.6405C179.974 64.754 180.976 65.8886 181.916 67.0652L182.243 67.4223L182.489 67.7375L183.163 68.5989L183.327 68.8089L183.94 69.5654L185.351 71.4983C185.985 72.3808 186.598 73.2842 187.17 74.2296L187.436 74.6078C188.009 75.5113 188.561 76.4147 189.113 77.3602C189.113 77.3602 189.256 77.6123 189.338 77.7383C190.033 78.9359 190.666 80.1546 191.382 81.3732C192.833 84.2107 194.13 87.1286 195.267 90.1135L195.573 90.8908C195.9 91.7943 196.207 92.6768 196.514 93.5802C196.657 94.0004 196.8 94.3996 196.923 94.8408C197.209 95.6812 197.454 96.5427 197.72 97.4252C197.863 97.9084 197.986 98.3915 198.108 98.8958C198.333 99.7362 198.579 100.577 198.783 101.438C198.926 101.963 199.049 102.51 199.171 103.056C199.478 104.443 199.764 105.85 200.051 107.258C200.194 108.014 200.337 108.771 200.459 109.548C200.582 110.325 200.664 110.956 200.766 111.649C200.868 112.342 200.991 113.225 201.114 114.023C201.236 114.822 201.257 115.431 201.339 116.124C201.42 116.818 201.523 117.805 201.604 118.646C201.686 119.486 201.707 120.011 201.747 120.747C201.788 121.482 201.87 122.616 201.911 123.583C201.911 124.192 201.911 124.823 201.911 125.453C201.911 126.777 201.911 128.121 201.911 129.466V130.18C201.911 158.565 193.733 181.824 178.277 197.413C162.821 213.003 140.536 220.945 113.284 220.945H34.1221V41.6426L0 3.19362V189.282L30.6669 224.328L31.3416 225H113.386C171.387 225 206 189.514 206 130.096C206 104.569 199.315 82.6969 186.578 66.2668C186.496 66.1617 185.269 64.712 185.269 64.712Z"
      />
    </svg>
  );
}

Logo.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default Logo;
