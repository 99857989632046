import PropTypes from "prop-types";

function OkayHand({ size = 223, className = "", ...restProps }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      className={className}
      viewBox="0 0 223 223"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <g filter="url(#filter0_f)">
        <circle cx="111.5" cy="111.5" r="81.5" fill="#FFDD85" />
      </g>
      <path
        d="M127.947 127.127C129.361 127.183 130.324 127.502 130.84 127.854C129.995 127.672 129.012 127.429 127.947 127.127Z"
        fill="#8FFF00"
      />
      <path
        stroke="#3662F6"
        strokeWidth="2"
        strokeLinejoin="round"
        d="M100.119 149.092C101.143 145.376 105.503 136.626 114.752 131.353C124 126.081 129.331 126.824 130.84 127.854C125.315 126.661 113.862 122.858 112.245 117.195C110.629 111.532 111.572 105.071 112.245 102.548L144.907 114.673C148.76 121.914 153.153 140.044 139.894 154.625C123.321 172.852 104.08 172.364 84.0307 160.728C63.9813 149.092 67.0534 122.81 71.0148 114.673"
      />
      <path
        stroke="#3662F6"
        strokeWidth="2"
        strokeLinejoin="round"
        d="M139.338 111.818L168.662 77.5075C169.531 75.1283 172.002 69.3639 167.115 65.3408C164.589 63.2622 157.041 63.6645 156.036 65.3408L122.558 105.734"
      />
      <path
        stroke="#3662F6"
        strokeWidth="2"
        strokeLinejoin="round"
        d="M85.8037 108.729C85.0796 107.486 82.6337 105.146 78.6429 105.73C73.6545 106.459 71.9648 110.755 71.9648 114.727C71.9648 117.904 74.4322 131.666 75.6659 138.15C76.7655 140.284 79.9946 144.309 84.1141 143.337C88.2336 142.364 90.6044 139.474 91.2749 138.15"
      />
      <path
        stroke="#3662F6"
        strokeWidth="2"
        strokeLinejoin="round"
        d="M103.088 101.165L107.882 133.266C107.205 135.602 104.762 140.436 100.407 141.088C96.0517 141.739 92.417 138.317 91.144 136.525L86.3501 105.727C85.9438 103.039 86.6264 97.368 92.6066 96.1947C98.5868 95.0215 102.086 99.0192 103.088 101.165Z"
      />
      <path
        stroke="#3662F6"
        strokeWidth="2"
        strokeLinejoin="round"
        d="M121.013 106.024V54.036C120.746 51.6692 118.865 46.9355 113.479 46.9355C108.092 46.9355 104.929 51.6692 104.021 54.036V106.024"
      />
      <defs>
        <filter
          x="0"
          y="0"
          width="223"
          height="223"
          id="filter0_f"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            result="shape"
            in="SourceGraphic"
            in2="BackgroundImageFix"
          />
          <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur" />
        </filter>
      </defs>
    </svg>
  );
}

OkayHand.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string
};

export default OkayHand;
