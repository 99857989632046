import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { Colors } from "./utils";
import { Select, Toggle, Checkbox, SimpleText, SimpleTextarea } from "./inputs";

function Form({ id, onSubmit, children }) {
  return (
    <form id={id} onSubmit={onSubmit} className="flex flex-1 flex-col">
      {children}
    </form>
  );
}

Form.propTypes = {
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

Form.Input = React.forwardRef(
  (
    {
      id,
      color,
      label,
      errors,
      subLabel,
      transform,
      labelUpper,
      placeholder,
      labelCapitalize,
      ...restProps
    },
    _
  ) => (
    <Controller
      {...restProps}
      render={({ field }) => (
        <SimpleText
          id={id}
          color={color}
          label={label}
          errors={errors}
          subLabel={subLabel}
          name={restProps.name}
          labelUpper={labelUpper}
          placeholder={placeholder}
          required={restProps.required}
          disabled={restProps.disabled}
          labelCapitalize={labelCapitalize}
          value={transform ? transform.input(field.value) : field.value}
          onChange={
            transform
              ? (e) => field.onChange(transform.output(e))
              : field.onChange
          }
        />
      )}
    />
  )
);

Form.Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.shape(),
  subLabel: PropTypes.string,
  labelUpper: PropTypes.bool,
  placeholder: PropTypes.string,
  labelCapitalize: PropTypes.bool,
  transform: PropTypes.shape({
    input: PropTypes.func,
    output: PropTypes.func
  }),
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

Form.Textarea = React.forwardRef(
  (
    { color, label, errors, subLabel, labelUpper, placeholder, ...restProps },
    _
  ) => (
    <Controller
      {...restProps}
      render={({ field }) => (
        <SimpleTextarea
          color={color}
          label={label}
          errors={errors}
          subLabel={subLabel}
          value={field.value}
          name={restProps.name}
          rows={restProps.rows}
          labelUpper={labelUpper}
          placeholder={placeholder}
          onChange={field.onChange}
          required={restProps.required}
          disabled={restProps.disabled}
        />
      )}
    />
  )
);

Form.Textarea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.shape(),
  subLabel: PropTypes.string,
  labelUpper: PropTypes.bool,
  placeholder: PropTypes.string,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

Form.Checkbox = React.forwardRef(
  ({ color, label, labelUpper, ...restProps }, _) => (
    <Controller
      {...restProps}
      render={({ field }) => (
        <Checkbox
          color={color}
          label={label}
          value={field.value}
          checked={field.value}
          name={restProps.name}
          labelUpper={labelUpper}
          required={restProps.required}
          disabled={restProps.disabled}
          onChange={(e) => field.onChange(e.target.checked)}
        />
      )}
    />
  )
);

Form.Checkbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  labelUpper: PropTypes.bool,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

Form.Toggle = React.forwardRef(
  ({ color, label, labelUpper, ...restProps }, _) => (
    <Controller
      {...restProps}
      render={({ field }) => (
        <Toggle
          color={color}
          label={label}
          value={field.value}
          checked={field.value}
          name={restProps.name}
          labelUpper={labelUpper}
          required={restProps.required}
          disabled={restProps.disabled}
          onChange={(e) => field.onChange(e.target.checked)}
        />
      )}
    />
  )
);

Form.Toggle.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  labelUpper: PropTypes.bool,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

Form.Select = React.forwardRef(
  (
    {
      color,
      multi,
      label,
      subLabel,
      checkbox,
      loadOptions,
      placeholder,
      closeOnSelect,
      ...restProps
    },
    _
  ) => (
    <Controller
      {...restProps}
      render={({ field }) => (
        <Select
          color={color}
          multi={multi}
          label={label}
          id={restProps.id}
          value={field.value}
          subLabel={subLabel}
          checkbox={checkbox}
          loadOptions={loadOptions}
          placeholder={placeholder}
          onChange={field.onChange}
          closeOnSelect={closeOnSelect}
          required={restProps.required}
          disabled={restProps.disabled}
          maxHeight={restProps.maxHeight}
          defaultValue={restProps.defaultValue}
        />
      )}
    />
  )
);

Form.Select.propTypes = {
  id: PropTypes.string,
  multi: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  checkbox: PropTypes.bool,
  subLabel: PropTypes.string,
  loadOptions: PropTypes.func,
  maxHeight: PropTypes.number,
  closeOnSelect: PropTypes.bool,
  placeholder: PropTypes.string,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

Form.ErrorMessage = (props) => (
  <ErrorMessage
    {...props}
    render={({ message }) => (
      <p className="text-sm font-semibold text-primary-red">{message}</p>
    )}
  />
);

Form.ErrorMessage.propTypes = {
  name: PropTypes.string,
  errors: PropTypes.shape()
};

export default Form;
