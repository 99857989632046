import SyncLoader from "react-spinners/SyncLoader";

function Loader() {
  return (
    <>
      <div
        style={{ zIndex: 80 }}
        className="
          flex
          fixed
          top-0
          flex-1
          left-0
          flex-col
          w-screen
          h-screen
          bg-black
          opacity-25
          items-center
          justify-center
        "
      />
      <div
        style={{ zIndex: 81 }}
        className="
          flex
          fixed
          top-0
          flex-1
          left-0
          flex-col
          w-screen
          h-screen
          opacity-100
          items-center
          justify-center
        "
      >
        <SyncLoader size={55} color="#3661F5" loading={true} />
      </div>
    </>
  );
}

export default Loader;
