import PropTypes from "prop-types";

function CollegeSmall({ size = 25, color = "stroke-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 61 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="2"
        className={color}
        strokeMiterlimit="10"
        d="M7.78902 21.077H36.8445M7.78902 12.4026H24.9839M38.3802 60.1014C38.3802 57.8969 37.5499 55.7827 36.0721 54.2238C34.5943 52.665 32.59 51.7893 30.5 51.7893C28.4101 51.7893 26.4057 52.665 24.9279 54.2238C23.4501 55.7827 22.6198 57.8969 22.6198 60.1014V63H38.3802V60.1014ZM35.1069 45.9921C35.1069 46.9532 34.8367 47.8927 34.3305 48.6918C33.8243 49.491 33.1047 50.1138 32.263 50.4816C31.4212 50.8494 30.4949 50.9456 29.6012 50.7581C28.7076 50.5706 27.8868 50.1078 27.2425 49.4282C26.5982 48.7486 26.1594 47.8827 25.9817 46.9401C25.8039 45.9975 25.8952 45.0204 26.2439 44.1325C26.5925 43.2445 27.183 42.4856 27.9406 41.9516C28.6981 41.4177 29.5889 41.1327 30.5 41.1327C31.7218 41.1327 32.8936 41.6447 33.7575 42.556C34.6215 43.4673 35.1069 44.7033 35.1069 45.9921ZM60 32.4582H1V1H60V32.4582Z"
      />
    </svg>
  );
}

CollegeSmall.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default CollegeSmall;
