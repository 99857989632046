import PropTypes from "prop-types";

function CloseMini({
  size = 25,
  fillColor = "fill-white",
  strokeColor = "stroke-black"
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 14 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="1"
        className={`${fillColor} ${strokeColor}`}
        d="M12.5567 7.48685C12.5567 11.1653 9.87842 13.9737 6.77834 13.9737C3.67827 13.9737 1 11.1653 1 7.48685C1 3.8084 3.67827 1 6.77834 1C9.87842 1 12.5567 3.8084 12.5567 7.48685Z"
      />
      <path
        strokeWidth="1"
        strokeLinejoin="round"
        className={strokeColor}
        d="M9.81119 4.38721L6.83594 7.56079L9.81119 10.7344"
      />
      <path
        strokeWidth="1"
        strokeLinejoin="round"
        className={strokeColor}
        d="M3.97396 10.7339L6.94922 7.5603L3.97396 4.38672"
      />
    </svg>
  );
}

CloseMini.propTypes = {
  size: PropTypes.number,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string
};

export default CloseMini;
