import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { ArrowDown, Check as CheckIcon } from "../icons/";

function Options({ item, fontSize, check = false, ...restProps }) {
  return (
    <div
      className="
        py-2
        pl-3
        pr-10
        relative
        select-none
        cursor-pointer
        hover:bg-light-gray
      "
      {...restProps}
    >
      <div className="flex items-center">
        <span className={`truncate capitalize font-medium ${fontSize}`}>
          {item.name}
        </span>
      </div>
      {check && (
        <span className="pr-4 flex right-0 inset-y-0 absolute items-center">
          <CheckIcon size={20} />
        </span>
      )}
    </div>
  );
}

Options.propTypes = {
  check: PropTypes.bool,
  item: PropTypes.shape().isRequired
};

function SimpleSelect({
  id,
  title,
  current,
  onSelect,
  placeholder,
  list = [],
  dropdownUp = false,
  fontSize = "text-xs"
}) {
  const divRef = useRef(null);
  const divRefScroll = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const [dropdownPosition, setDropdownPosition] = useState(0);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        showDropdown &&
        divRef &&
        divRef.current &&
        !divRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    if (divRefScroll.current) divRefScroll.current.children[0].focus();
  }, [showDropdown]);

  const handleOptionsEvents = (option, index, event) => {
    if (event.key === "Enter") {
      setShowDropdown(false);
      onSelect(option);
    }
    if (event.key === "ArrowUp") {
      if (index > 0) divRefScroll.current.children[index - 1].focus();
    }
    if (event.key === "ArrowDown")
      if (index < list.length - 1)
        divRefScroll.current.children[index + 1].focus();
  };

  useEffect(
    () => setDropdownPosition(divRefScroll.current?.clientHeight || 0),
    [showDropdown]
  );

  let styleUp = {};
  if (dropdownUp) {
    styleUp = {
      style: {
        top: `-${dropdownPosition}px`
      }
    };
  }

  return (
    <div ref={divRef} className="w-full">
      {title && (
        <p className={`capitalize text-primary-blue font-semibold ${fontSize}`}>
          {title}
        </p>
      )}
      <div className="relative" id={id}>
        <div
          tabIndex="0"
          role="button"
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === " ") {
              setShowDropdown(!showDropdown);
            }
          }}
          className={`
            py-2
            pl-3
            pr-10
            w-full
            border
            relative
            bg-white
            shadow-sm
            text-left
            border-black
            cursor-pointer
            ${showDropdown ? "shadow-black" : ""}
          `}
        >
          <span className="flex items-center">
            <span
              className={`
                truncate
                capitalize
                font-medium
                ${fontSize}
                ${!current && "text-gray-500"}
              `}
            >
              {current || placeholder || "Choose One"}
            </span>
          </span>
          <span
            className="
              ml-3
              pr-2
              flex
              right-0
              absolute
              inset-y-0
              items-center
              pointer-events-none
            "
          >
            <ArrowDown
              className={`
                transform
                transition
                duration-300
                text-black
                ${showDropdown ? "-rotate-180" : ""}
              `}
            />
          </span>
        </div>
        {showDropdown && (
          <div
            ref={divRefScroll}
            className="
              z-10
              w-full
              absolute
              max-h-40
              bg-white
              min-h-2rem
              shadow-black
              overflow-y-auto
            "
            {...styleUp}
          >
            {list.map((item, index) => (
              <Options
                item={item}
                tabIndex="0"
                role="option"
                key={item.id}
                fontSize={fontSize}
                check={item.name === current}
                onClick={() => {
                  setShowDropdown(false);
                  onSelect(item);
                }}
                onKeyDown={(event) => handleOptionsEvents(item, index, event)}
              />
            ))}
            {list.length === 0 && (
              <div className={`py-2 text-center text-gray-500 ${fontSize}`}>
                No items
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

SimpleSelect.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  current: PropTypes.string,
  fontSize: PropTypes.string,
  dropdownUp: PropTypes.bool,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape())
};

export default SimpleSelect;
