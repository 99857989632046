import PuffLoader from "react-spinners/PuffLoader";
import PropTypes from "prop-types";

function LoadingButton({
  children,
  disabled = false,
  isLoading = false,
  className,
  ...restProps
}) {
  return (
    <button
      tabIndex="0"
      disabled={isLoading || disabled}
      className={`
        py-2
        px-5
        border
        relative
        capitalize
        font-extrabold
        ${className}
        ${
          isLoading || disabled
            ? "opacity-40 cursor-not-allowed pointer-events-none"
            : ""
        }
      `}
      {...restProps}
    >
      {children}
      {isLoading && (
        <div className="absolute inset-0 flex flex-1 flex-row items-center">
          <PuffLoader size={40} loading={isLoading} />
        </div>
      )}
    </button>
  );
}

LoadingButton.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string
};

export default LoadingButton;
