import Types from "./types";

import Api from "../../services/Api";

function setReset() {
  return {
    type: Types.RESET
  };
}

function setError(error) {
  return {
    type: Types.SET_ERROR,
    error
  };
}

function setIsLoading(isLoading) {
  return {
    type: Types.SET_IS_LOADING,
    isLoading
  };
}

function setOrganization(organization) {
  return {
    type: Types.SET_ORGANIZATION,
    organization
  };
}

export function resetOrganization() {
  return (dispatch) => {
    dispatch(setReset());
  };
}

export function fetchOrganization({ organizationId }) {
  return async (dispatch) => {
    try {
      dispatch(setError(null));
      dispatch(setIsLoading(true));

      const response = await Api.fetchOrganization({
        organizationId
      });

      const organizationWithRole = {
        ...response.organization,
        role: response.role
      };

      dispatch(setOrganization(organizationWithRole));
    } catch (error) {
      dispatch(setError(error));
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}
