const RESET = "ORGANIZATION/RESET";
const SET_ERROR = "ORGANIZATION/SET_ERROR";
const SET_IS_LOADING = "ORGANIZATION/SET_IS_LOADING";
const SET_ORGANIZATION = "ORGANIZATION/SET_ORGANIZATION";

const Types = {
  RESET,
  SET_ERROR,
  SET_IS_LOADING,
  SET_ORGANIZATION
};

export default Types;
