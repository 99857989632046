import PropTypes from "prop-types";

function CollegeMedium({ size = 25, color = "stroke-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 48 56"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="2"
        className={color}
        strokeMiterlimit="10"
        d="M6.29356 18.2157H28.7484M6.29356 10.7879H19.5822M29.9351 51.7507C29.9351 49.8584 29.2935 48.0435 28.1514 46.7054C27.0093 45.3674 25.4603 44.6156 23.8452 44.6156C22.23 44.6156 20.681 45.3674 19.5389 46.7054C18.3968 48.0435 17.7552 49.8584 17.7552 51.7507V54.2389H29.9351V51.7507ZM27.4055 39.621C27.4055 40.4461 27.1967 41.2525 26.8054 41.9385C26.4142 42.6244 25.8582 43.1591 25.2076 43.4748C24.557 43.7905 23.8412 43.8731 23.1506 43.7122C22.4599 43.5512 21.8256 43.154 21.3277 42.5706C20.8297 41.9872 20.4906 41.244 20.3533 40.4348C20.2159 39.6257 20.2864 38.787 20.5559 38.0248C20.8254 37.2626 21.2817 36.6111 21.8671 36.1527C22.4526 35.6944 23.141 35.4498 23.8452 35.4498C24.7894 35.4498 25.695 35.8892 26.3626 36.6715C27.0303 37.4538 27.4055 38.5148 27.4055 39.621ZM13.2112 51.7507C13.253 50.7846 13.1266 49.8185 12.8398 48.9114C12.553 48.0042 12.1118 47.1748 11.5429 46.4736C10.974 45.7725 10.2893 45.2142 9.53056 44.8327C8.77177 44.4512 7.95469 44.2544 7.12898 44.2544C6.30328 44.2544 5.4862 44.4512 4.72741 44.8327C3.96862 45.2142 3.28396 45.7725 2.71507 46.4736C2.14618 47.1748 1.70495 48.0042 1.41816 48.9114C1.13138 49.8185 1.00505 50.7846 1.04684 51.7507V54.2389H13.2112V51.7507ZM10.6815 39.621C10.6814 40.4468 10.4722 41.254 10.0803 41.9404C9.68844 42.6267 9.13149 43.1614 8.48002 43.4766C7.82856 43.7917 7.11189 43.8732 6.42084 43.7108C5.72979 43.5484 5.09546 43.1493 4.59817 42.5641C4.10088 41.9789 3.76301 41.2339 3.62741 40.4236C3.4918 39.6132 3.56455 38.7739 3.83642 38.012C4.10828 37.2502 4.56704 36.6 5.15458 36.1438C5.74211 35.6877 6.43198 35.4461 7.1368 35.4498C7.60361 35.4498 8.06581 35.5578 8.49689 35.7676C8.92797 35.9775 9.31943 36.285 9.64879 36.6726C9.97814 37.0602 10.2389 37.5202 10.4162 38.0261C10.5934 38.5321 10.6835 39.0741 10.6815 39.621ZM46.5966 28.0036H1V1H46.5966V28.0036Z"
      />
    </svg>
  );
}

CollegeMedium.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default CollegeMedium;
