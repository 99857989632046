const RESET = "WEBHOOK/RESET";
const SET_PAGE = "WEBHOOK/SET_PAGE";
const SET_ERROR = "WEBHOOK/SET_ERROR";
const RESET_PAGE = "WEBHOOK/RESET_PAGE";
const SET_WEBHOOK = "WEBHOOK/SET_WEBHOOK";
const SET_IS_LOADING = "WEBHOOK/SET_IS_LOADING";

const Types = {
  RESET,
  SET_PAGE,
  SET_ERROR,
  RESET_PAGE,
  SET_WEBHOOK,
  SET_IS_LOADING
};

export default Types;
