import React from "react";
import { Link, useLocation } from "react-router-dom";

import { useOrganization } from "../../../redux/Organization/hooks";
import { ROLES } from "../../Common/Utils";

function NavLink({ to, children }) {
  const location = useLocation();
  const isActive = location.pathname.startsWith(to);

  return (
    <li className="border-b-2 first:border-t-2 border-grey-200 ml-10 mr-2 py-4">
      <Link to={to}>
        <p
          className={`font-semibold hover:text-primary-blue hover:underline ${
            isActive && "text-primary-blue"
          }`}
        >
          {children}
        </p>
      </Link>
    </li>
  );
}

export default function Sidebar() {
  const { organization } = useOrganization();
  return (
    <div
      className="
      flex
      flex-1
      flex-col
      bg-white
      border-r-2
      border-grey-200
      space-y-8"
    >
      <ul
        className="
          pt-8  
          py-4
          text-lg
          font-bold"
      >
        <NavLink to="/upload">Upload</NavLink>
        <NavLink to="/jobs">Jobs</NavLink>
        {organization?.role === ROLES.ADMIN && (
          <>
            <NavLink to="/org-settings">Org Settings</NavLink>
            {/* <NavLink to="/billing">Billing</NavLink> */}
          </>
        )}
      </ul>
      <ul
        className="
          py-4
          text-base
          "
      >
        <NavLink to="/graphql">GraphQL</NavLink>
        <NavLink to="/webhooks">Webhooks</NavLink>
        <NavLink to="/api-keys">Manage API Keys</NavLink>
      </ul>
    </div>
  );
}
