const extractEmails = (text) => {
  return text.match(/([\w+-.%]+@[\w-.]+\.[a-zA-Z0-9_-]+)/gi) || [];
};

export { extractEmails };

export const ROLES = {
  ADMIN: "ADMIN",
  USER: "USER"
};

export const GetAccess = () => {
  return [
    { value: ROLES.ADMIN, label: "Administrator" },
    { value: ROLES.USER, label: "User" }
  ];
};
