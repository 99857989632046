import PropTypes from "prop-types";

function Question({
  size = 50,
  className = "",
  iconColor = "fill-black",
  fillColor = "fill-white",
  strokeColor = "stroke-black",
  ...restProps
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 31 32"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        className={`${fillColor} ${strokeColor}`}
        d="M30.3 16.4399C30.3 24.7627 23.6242 31.5009 15.4 31.5009C7.17585 31.5009 0.5 24.7627 0.5 16.4399C0.5 8.11702 7.17585 1.37891 15.4 1.37891C23.6242 1.37891 30.3 8.11702 30.3 16.4399Z"
      />
      <path
        className={iconColor}
        d="M13.5103 20.5435C13.5103 19.4041 13.6486 18.4967 13.9253 17.8213C14.202 17.1458 14.7065 16.4826 15.439 15.8315C16.1795 15.1724 16.6719 14.6393 16.916 14.2324C17.1602 13.8174 17.2822 13.382 17.2822 12.9263C17.2822 11.5509 16.6475 10.8633 15.3779 10.8633C14.7757 10.8633 14.2915 11.0505 13.9253 11.4248C13.5672 11.791 13.38 12.2996 13.3638 12.9507H9.82373C9.84001 11.3963 10.3405 10.1797 11.3252 9.30078C12.318 8.42188 13.6689 7.98242 15.3779 7.98242C17.1032 7.98242 18.4419 8.40153 19.394 9.23975C20.3462 10.0698 20.8223 11.2458 20.8223 12.7676C20.8223 13.4593 20.6676 14.1144 20.3584 14.7329C20.0492 15.3433 19.508 16.0228 18.7349 16.7715L17.7461 17.7114C17.1276 18.3055 16.7736 19.0013 16.6841 19.7988L16.6353 20.5435H13.5103ZM13.1562 24.291C13.1562 23.7458 13.3394 23.2982 13.7056 22.9482C14.0799 22.5902 14.556 22.4111 15.1338 22.4111C15.7116 22.4111 16.1836 22.5902 16.5498 22.9482C16.9242 23.2982 17.1113 23.7458 17.1113 24.291C17.1113 24.8281 16.9282 25.2716 16.562 25.6216C16.2039 25.9715 15.7279 26.1465 15.1338 26.1465C14.5397 26.1465 14.0596 25.9715 13.6934 25.6216C13.3353 25.2716 13.1562 24.8281 13.1562 24.291Z"
      />
    </svg>
  );
}

Question.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  fillColor: PropTypes.string,
  iconColor: PropTypes.string,
  strokeColor: PropTypes.string
};

export default Question;
