import { combineReducers } from "redux";

import { jobReducer } from "./Job/reducer";
import { jobsReducer } from "./Jobs/reducer";
import { userReducer } from "./User/reducer";
import { usersReducer } from "./Users/reducer";
import { webhookReducer } from "./Webhook/reducer";
import { webhooksReducer } from "./Webhooks/reducer";
import { organizationReducer } from "./Organization/reducer";
import { apiKeysReducer } from "./APIKeys/reducer";

const rootReducer = combineReducers({
  job: jobReducer,
  jobs: jobsReducer,
  user: userReducer,
  users: usersReducer,
  webhook: webhookReducer,
  webhooks: webhooksReducer,
  organization: organizationReducer,
  apiKeys: apiKeysReducer
});

export default rootReducer;
