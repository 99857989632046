import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useLogin } from "../../utils/auth";
import { useUser } from "../../redux/User/hooks";
import { fetchUser } from "../../redux/User/actions";

function PrivateRoute({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const user = useUser();
  const { isLoading, mainAppUserExists, error } = useLogin();

  const [ready, setReady] = useState(false);

  useEffect(() => {
    (async () => {
      if (!isLoading && !error) {
        if (mainAppUserExists) {
          if (!user.isLoggedIn) {
            await dispatch(fetchUser());
          }
          setReady(true);
        } else {
          const qs = new URLSearchParams(location.search.slice(1));
          let redirectUrl = "/create-account";
          if (qs.get("next")) {
            redirectUrl += `?next=${encodeURIComponent(qs.get("next"))}`;
          }
          navigate(redirectUrl);
        }
      }
    })();
  }, [
    dispatch,
    navigate,
    error,
    location,
    isLoading,
    mainAppUserExists,
    user.isLoggedIn
  ]);

  if (ready) {
    return children;
  } else {
    // FIXME: Display something
    return <></>;
  }
}

export default PrivateRoute;
