import { forceUserToSignIn } from "../utils/auth";
import { apiVersionErrorToast } from "../utils/unexpectedErrorToast";

const ErrorTypes = {
  authError: "AuthError",
  apiVersionError: "ApiVersionError",
  graphQLError: "GraphQLError"
};

class DecidedApi {
  constructor({ token, baseUrl = null } = {}) {
    this.configure({ token, baseUrl });
  }

  configure({ token, baseUrl }) {
    this.token = token;
    this.baseUrl = baseUrl || window.SERVER_DATA.REACT_APP_BACKEND_URL;
    this.graphqlUrl = `${this.baseUrl}/api/private/graphql/`;
    this.signinUrl = `${this.baseUrl}/api/private/rest/signin`;
    this.signupUrl = `${this.baseUrl}/api/private/rest/signup`;
    this.signinAdvisorUrl = `${this.baseUrl}/advisors/api/signin`;
    this.newVerifyLinkUrl = `${this.baseUrl}/api/private/rest/send-email-verification-link`;
  }

  formatDate(date) {
    if (date)
      return (
        ("0000" + date.getFullYear()).slice(-4) +
        "-" +
        ("00" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("00" + date.getDate()).slice(-2)
      );
    return date;
  }

  uploadFile(file, callback, callbackProgress) {
    const xhr = new XMLHttpRequest();
    xhr.open("put", file.uploadUrl);
    xhr.setRequestHeader("Content-Type", "application/octet-stream");
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) return callback(true, file.id);
        else return callback(false, file.id, "error");
      }
    };
    xhr.upload.onabort = () => {
      return callback(false, file.id, "abort");
    };
    xhr.upload.onprogress = (event) =>
      callbackProgress(Math.round((event.loaded / event.total) * 100), file.id);
    xhr.send(file.file);

    return xhr;
  }

  async fetch(url, body, options) {
    const getToken = async () => {
      try {
        return await this.token();
      } catch (e) {
        forceUserToSignIn();
        throw e;
      }
    };

    const token = await getToken();

    const response = await fetch(url, {
      ...options,
      headers: {
        ...options?.headers,
        Authorization: `JWT ${token}`,
        "Api-Revision": window.SERVER_DATA.REACT_APP_REVISION
      },
      body: body && JSON.stringify(body)
    });

    if (response.status === 418) {
      apiVersionErrorToast();
      throw Object.assign(new Error(), { type: ErrorTypes.apiVersionError });
    }

    return response;
  }

  async gqlQuery(body) {
    const response = await this.fetch(this.graphqlUrl, body, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (response.status === 403) {
      forceUserToSignIn();
      throw Object.assign(new Error(), { type: ErrorTypes.authError });
    }

    const responseBody = await response.json();
    if (responseBody?.errors) {
      throw Object.assign(new Error(), {
        type: ErrorTypes.graphQLError,
        errors: responseBody.errors
      });
    }
    return responseBody;
  }

  async checkUserInAdvisor() {
    const responseAdvisor = await this.fetch(this.signinAdvisorUrl, undefined, {
      method: "POST"
    });
    return responseAdvisor.ok;
  }

  async checkUserInMainApp() {
    const responseMainApp = await this.fetch(this.signinUrl, undefined, {
      method: "POST"
    });
    return responseMainApp.ok;
  }

  async newVerifyLink(resultUrl) {
    const response = await this.fetch(
      this.newVerifyLinkUrl,
      { result_url: resultUrl },
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    return response.ok;
  }

  async fetchAdvisorUser() {
    const advisorGraphqlUrl = `${this.baseUrl}/advisors/graphql/`;

    const query = `
    query FetchAdvisor {
      advisor {
        zip
        title
        phoneNumber
        emailReceive
        firstName
        lastName
      }
    }
  `;

    const response = await this.fetch(
      advisorGraphqlUrl,
      { query: query, variables: {} },
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );

    if (response.status === 403) {
      forceUserToSignIn();
      throw Object.assign(new Error(), { type: ErrorTypes.authError });
    }

    const responseBody = await response.json();
    if (responseBody?.errors) {
      throw Object.assign(new Error(), {
        type: ErrorTypes.graphQLError,
        errors: responseBody.errors
      });
    }
    return responseBody.data?.advisor;
  }

  async signUp({ firstName, lastName, phoneNumber, zip, title, emailReceive }) {
    const body = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      zip: zip,
      title: title,
      email_receive: emailReceive
    };
    const response = await this.fetch(this.signupUrl, body, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    });
    return response;
  }

  async fetchUser() {
    const query = `
      query FetchUser {
        user {
          email,
          lastName,
          firstName,
          organizationUsers {
            organization {
              id,
              name,
            }
          }
        }
        __schema {
          types {
            name
            fields {
              name,
              description
            },
            inputFields {
              name,
              type {
                name,
                kind
              },
              description
            },
            enumValues {
              name,
              description
            }
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {}
    });

    return data;
  }

  async fetchJobs({ page, filter, orderBy, organizationId }) {
    const query = `
      query FetchJobs(
        $page: Page,
        $organizationId: ID,
        $filter: JobFilter,
        $orderBy: JobOrderBy,
      ) {
        user {
          organizationUsers(organizationId: $organizationId) {
            organization {
              jobs(
                page: $page,
                filter: $filter,
                orderBy: $orderBy,
              ) {
                pageCount,
                itemsCount,
                items {
                  id,
                  name,
                  insertTime,
                  lastUpdated,
                  user {
                    lastName,
                    firstName,
                  },
                  awardLetters {
                    itemsCount
                  }
                }
              }
            }
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        page,
        orderBy,
        organizationId,
        filter
      }
    });

    return data?.user?.organizationUsers[0]?.organization?.jobs;
  }

  async fetchAwardLetters({ page, filter, orderBy, organizationId }) {
    const query = `
      query FetchAwardLetters(
        $page: Page,
        $organizationId: ID,
        $filter: JobFilter,
        $orderBy: AwardLetterOrderBy
      ) {
        user {
          organizationUsers(organizationId: $organizationId) {
            organization {
              jobs(filter: $filter) {
                items {
                  awardLetters(
                    page: $page
                    orderBy: $orderBy
                  ) {
                    pageCount,
                    itemsCount,
                    items {
                      id,
                      status,
                      filename,
                      lastStatusUpdate,
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        page,
        filter,
        orderBy,
        organizationId
      }
    });

    return data?.user?.organizationUsers[0]?.organization?.jobs?.items[0]
      ?.awardLetters;
  }

  async fetchAwardLettersStatus({ filter, statusTypes, organizationId }) {
    const query = `
      query FetchAwardLettersStatus(
        $filter: JobFilter,
        $organizationId: ID,
      ) {
        ${Object.keys(statusTypes).map(
          (key) => `${key}: user {
            organizationUsers(organizationId: $organizationId) {
              organization {
                jobs(filter: $filter) {
                  items {
                    awardLetters(filter: {status: ${key}}) {
                      itemsCount
                    }
                  }
                }
              }
            }
          }`
        )}
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        filter,
        organizationId
      }
    });

    return Object.keys(data).reduce((array, key) => {
      const report = {
        status: key,
        count:
          data[key].organizationUsers[0].organization.jobs.items[0].awardLetters
            .itemsCount
      };
      array.push(report);
      return array;
    }, []);
  }

  async createJob({
    name,
    notify,
    manualReview,
    filesPurged,
    filenames,
    organizationId
  }) {
    const mutation = `
      mutation CreateJob(
        $name: String,
        $organizationId: ID!,
        $purgeLetterFilesAfter: Int,
        $notifyOnCompletion: Boolean,
        $letterFiles: [LetterFileInput!]!
        $manualReviewEnabled: Boolean
      ) {
        createJob(
          name: $name,
          letterFiles: $letterFiles,
          organizationId: $organizationId,
          notifyOnCompletion: $notifyOnCompletion,
          manualReviewEnabled: $manualReviewEnabled
          purgeLetterFilesAfter: $purgeLetterFilesAfter,
        ) {
          job {
            id
          }
          awardLetters {
            id
            uploadUrl
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        name,
        organizationId,
        notifyOnCompletion: notify,
        manualReviewEnabled: manualReview,
        purgeLetterFilesAfter: filesPurged,
        letterFiles: filenames.map((filename) => ({ filename: filename }))
      }
    });

    return data?.createJob;
  }

  async cancelJob({ jobsId, organizationId }) {
    const mutation = `
      mutation CancelJob($jobIdList: [ID!]!, $organizationId: ID!) {
        cancelJob(jobIdList: $jobIdList, organizationId: $organizationId) {
          canceledJobs {
            jobId
            canceledLetterCount
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId,
        jobIdList: jobsId
      }
    });

    return data?.cancelJob;
  }

  async cancelAwardLetter({ id, organizationId }) {
    const mutation = `
      mutation CancelAwardLetter($id: ID!, $organizationId: ID!) {
        cancelAwardLetter(id: $id, organizationId: $organizationId) {
          ok
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        id,
        organizationId
      }
    });

    return data?.cancelAwardLetter;
  }

  async exportJob({ jobsId, organizationId }) {
    const mutation = `
      mutation ExportJob($jobIdList: [ID!]!, $organizationId: ID!) {
        exportJob(jobIdList: $jobIdList, organizationId: $organizationId) {
          exportedJobsCount
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId,
        jobIdList: jobsId
      }
    });

    return data?.exportJob;
  }

  async deleteJob({ jobsId, organizationId }) {
    const mutation = `
      mutation DeleteJob($jobIdList: [ID!]!, $organizationId: ID!) {
        deleteJob(jobIdList: $jobIdList, organizationId: $organizationId) {
          deletedJobsCount
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId,
        jobIdList: jobsId
      }
    });

    return data?.deleteJob;
  }

  async fetchWebhooks({ orderBy, organizationId }) {
    const query = `
      query FetchWebhooks(
        $organizationId: ID,
        $orderBy: WebhookOrderBy,
      ) {
        user {
          organizationUsers(organizationId: $organizationId) {
            organization {
              webhooks(
                orderBy: $orderBy,
              ) {
                id,
                url,
                name,
                method,
              }
            }
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        orderBy,
        organizationId
      }
    });

    return data?.user?.organizationUsers[0]?.organization?.webhooks;
  }

  async fetchWebhook({ filter, organizationId }) {
    const query = `
      query FetchWebhook(
        $organizationId: ID,
        $filter: WebhookFilter
      ) {
        user {
          organizationUsers(organizationId: $organizationId) {
            organization {
              webhooks(
                filter: $filter,
              ) {
                id,
                url,
                name,
                method,
                headers,
                notification {
                  notifyLetterReady,
                  notifyLetterInvalid,
                  notifyLetterBadFile,
                  notifyLetterCanceled,
                  notifyLetterNotSupported,
                  notifyLetterManualReview,
                  notifyLetterSizeLimitExceeded,
                },
                payload {
                  returnAwardLetterId,
                  returnAwardLetterStatus,
                  returnAwardLetterMethod,
                  returnAwardLetterPayload,
                  returnAwardLetterFilename,
                  returnAwardLetterInsertTime,
                  returnJob {
                    returnJobId,
                    returnJobInsertTime
                  },
                  returnAidAmount {
                    returnAidAmountValue,
                    returnAidAmountSource,
                    returnAidAmountCategory,
                    returnAidAmountDescription,
                    returnAidAmountSubCategory
                  },
                  returnCostAmount {
                    returnCostAmountValue,
                    returnCostCategoryCategory,
                    returnCostAmountDescription
                  }
                },
              }
            }
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        filter,
        organizationId
      }
    });

    return data?.user?.organizationUsers[0]?.organization?.webhooks[0];
  }

  async fetchWebhookLogs({ page, filter, orderBy, organizationId }) {
    const query = `
      query FetchWebhook(
        $page: Page,
        $organizationId: ID,
        $filter: WebhookLogFilter
        $orderBy: WebhookLogOrderBy,
      ) {
        user {
          organizationUsers(organizationId: $organizationId) {
            organization {
              webhookLogs(
                page: $page,
                filter: $filter,
                orderBy: $orderBy,
              ) {
                pageCount,
                itemsCount,
                items {
                  id
                  insertTime
                  errorMessage
                }
              }
            }
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        page,
        filter,
        orderBy,
        organizationId
      }
    });

    return data?.user?.organizationUsers[0]?.organization?.webhookLogs;
  }

  async fetchUsers({ page, filter, orderBy, organizationId }) {
    const query = `
    query FetchUsers(
      $page: Page,
      $organizationId: ID,
      $filter: OrganizationUserFilter
      $orderBy: OrganizationUserOrderBy,
    ) {
      user {
        organizationUsers(organizationId: $organizationId) {
          organization {
            organizationUsers(
              page: $page,
              filter: $filter,
              orderBy: $orderBy,
            ) {
              pageCount,
              itemsCount,
              items {
                id
                role
                user {
                  firstName
                  lastName
                  email
                }
                jobs{
                  itemsCount
                  items{
                    insertTime
                  }
                }
              }
            }
          }
        }
      }
    }   `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        page,
        filter,
        orderBy,
        organizationId
      }
    });

    return data?.user?.organizationUsers[0]?.organization?.organizationUsers;
  }

  async fetchAPIKeys({ page }) {
    const query = `
    query FetchAPIKeys(
      $page: Page,
    ) {
      user {
        apiKeys(page: $page) {
          itemsCount,
          pageCount,
          items {
            key
            expirationDate
            insertTime
            isExpired
          }
        }
      }
    }   `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        page
      }
    });

    return data?.user?.apiKeys;
  }

  async removeUserFromOrganization({ input, organizationId }) {
    const mutation = `
      mutation RemoveUserFromOrganization(
        $organizationId: ID!,
        $input: UpdateOrganizationInput!
      ) {
        updateOrganization(
          input: $input,
          organizationId: $organizationId
        ) {
            id
          }
        }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        input,
        organizationId
      }
    });

    return data?.updateOrganization;
  }

  async updateUserRoleInOrg({ organizationId, organizationApiUserIds, role }) {
    const mutation = `
      mutation UpdateUserRoleInOrg (
        $organizationId: ID!,
        $organizationApiUserIds: [ID!]!
        $role: _Role!
      ) {
        updateUserRoleInOrg(
          organizationId: $organizationId,
          organizationApiUserIds: $organizationApiUserIds,
          role: $role
        ) {
          ok
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId,
        organizationApiUserIds,
        role
      }
    });

    return data?.updateUserRoleInOrg;
  }

  async fetchWebhookLog({ filter, organizationId }) {
    const query = `
      query FetchWebhookLog(
        $organizationId: ID,
        $filter: WebhookLogFilter
      ) {
        user {
          organizationUsers(organizationId: $organizationId) {
            organization {
              webhookLogs(filter: $filter) {
                items {
                  id
                  body
                  headers
                  insertTime
                  errorMessage
                }
              }
            }
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        filter,
        organizationId
      }
    });

    return data?.user?.organizationUsers[0]?.organization?.webhookLogs
      ?.items[0];
  }

  async fetchUsageBilling({ organizationId, year, month }) {
    const query = `
      query FetchUsageBilling(
        $organizationId: ID,
        $year: Int!,
        $month: Int!,
      ) {
        user {
          organizationUsers(organizationId: $organizationId) {
            organization {
              classificationUsage(year: $year, month: $month) 
              manualReviewUsage(year: $year, month: $month)
              billSummary(year: $year, month: $month)
            }
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        organizationId,
        year,
        month
      }
    });

    return data?.user?.organizationUsers[0]?.organization;
  }

  async fetchPriceStructure({ organizationId, year, month }) {
    const query = `
      query FetchPriceStructure(
        $organizationId: ID,
        $year: Int!,
        $month: Int!,
      ) {
        user {
          organizationUsers(organizationId: $organizationId) {
            organization {
              priceStructure (year: $year, month: $month){
                classifications {
                  minUsage
                  price
                }
                manualReviews {
                  minUsage
                  price
                }
              }
            }
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        organizationId,
        year,
        month
      }
    });

    return data?.user?.organizationUsers[0]?.organization?.priceStructure;
  }

  async invoiceDownloadURL({ organizationId, year, month }) {
    const query = `
      query InvoiceDownloadURL(
        $organizationId: ID,
        $year: Int!,
        $month: Int!,
      ) {
        user {
          organizationUsers(organizationId: $organizationId) {
            organization {
              invoiceDownloadUrl (year: $year, month: $month)
            }
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        organizationId,
        year,
        month
      }
    });

    return data?.user?.organizationUsers[0]?.organization?.invoiceDownloadUrl;
  }

  async testWebhook({ input, organizationId }) {
    const mutation = `
      mutation TestWebhook(
        $organizationId: ID!,
        $input: CreateWebhookInput!
      ) {
        testWebhook(
          input: $input,
          organizationId: $organizationId
        ) {
          url,
          body,
          headers,
          insertTime,
          statusCode,
          errorMessage,
          responseBody,
          responseHeaders,
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        input,
        organizationId
      }
    });

    return data?.testWebhook;
  }

  async createWebhook({ input, organizationId }) {
    const mutation = `
      mutation CreateWebhook(
        $organizationId: ID!,
        $input: CreateWebhookInput!
      ) {
        createWebhook(
          input: $input,
          organizationId: $organizationId
        ) {
          id,
          url,
          name
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        input,
        organizationId
      }
    });

    return data?.createWebhook;
  }

  async updateWebhook({ input, organizationId }) {
    const mutation = `
      mutation UpdateWebhook(
        $organizationId: ID!,
        $input: UpdateWebhookInput!
      ) {
        updateWebhook(
          input: $input,
          organizationId: $organizationId
        ) {
          id,
          url,
          name
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        input,
        organizationId
      }
    });

    return data?.createWebhook;
  }

  async deleteWebhook({ id, organizationId }) {
    const mutation = `
      mutation DeleteWebhook($id: ID!, $organizationId: ID!) {
        deleteWebhook(id: $id, organizationId: $organizationId) {
          id
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        id,
        organizationId
      }
    });

    return data?.deleteWebhook;
  }

  async fetchOrganization({ organizationId }) {
    const query = `
      query FetchOrganization($organizationId: ID) {
        user {
          organizationUsers(organizationId: $organizationId) {
            role,
            organization {
              id,
              name,
              credits,
              billingStatus,
              manualReviewEnabled,
              manualReviewSizeLimit,
              manualReviewNoFreeMoney
              purgeLetterFilesAfter,
            }
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: { organizationId }
    });

    return data?.user?.organizationUsers[0];
  }

  async updateOrganization({ input, organizationId }) {
    const mutation = `
      mutation UpdateOrganization(
        $organizationId: ID!
        $input: UpdateOrganizationInput!,
      ) {
        updateOrganization(
          input: $input,
          organizationId: $organizationId
        ) {
          id
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        input,
        organizationId
      }
    });

    return data?.updateOrganization;
  }

  async disableAPIKey({ key }) {
    const mutation = `
      mutation DisableAPIKey(
        $key: String!,
      ) {
        disableApiKey(
          key: $key
        ) {
          key
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        key
      }
    });

    return data?.disableAPIKey;
  }

  async createApiKey({ lifetime }) {
    const mutation = `
      mutation CreateApiKey(
        $lifetime: Int,
      ) {
        createApiKey(
          lifetime: $lifetime,
        ) {
          key
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        lifetime
      }
    });

    return data?.createApiKey;
  }

  async createOrganization({ name }) {
    const mutation = `
      mutation CreateOrganization(
        $name: String!,
      ) {
        createOrganization(
          name: $name,
        ) {
          id
          name
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        name
      }
    });

    return data?.createOrganization;
  }

  async organizationsToJoin() {
    const query = `
      query OrganizationsToJoin {
        organizationsToJoin {
          name
          id
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {}
    });

    return data.organizationsToJoin;
  }

  async joinOrganization({ organizationId }) {
    const mutation = `
      mutation JoinOrganization(
        $organizationId: Int!,
      ) {
        joinOrganization(
          organizationId: $organizationId,
        ) {
          ok
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId
      }
    });

    return data?.joinOrganization;
  }

  async myPendingInvitations() {
    const query = `
      query MyPendingInvitations {
        myPendingInvitations {
          id
          recipient
          subject
          body
          status
          insertTime
          organizationId
          sender {
            organization { 
              id
              name
            }
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {}
    });

    return data.myPendingInvitations;
  }

  async inviteUser({ emails, organizationId, role }) {
    const mutation = `
      mutation InviteUser(
        $emails: [String!]!,
        $organizationId: ID!,
        $role: _Role!
      ) {
        inviteUser(
          organizationId: $organizationId,
          emails: $emails,
          role: $role
        ) {
          invitationsSent
          alreadyBelongToOrg
          invitationsAlreadyPending {
            id
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        emails,
        organizationId,
        role
      }
    });

    return data?.inviteUser;
  }

  async acceptInvitation({ invitationId }) {
    const mutation = `
      mutation AcceptInvitation(
        $invitationId: ID!,
      ) {
        acceptInvitation(
          invitationId: $invitationId,
        ) {
          id
          organizationId
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        invitationId
      }
    });

    return data?.acceptInvitation;
  }

  async changeOrganizationPlan({ organizationId, billingStatus }) {
    const mutation = `
      mutation ChangeOrganizationPlan(
        $organizationId: ID!,
        $billingStatus: _BillingStatus!,
      ) {
        changeOrganizationPlan(
          organizationId: $organizationId,
          billingStatus: $billingStatus,
        ) {
          ok
          setupIntentId
          clientSecret
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId,
        billingStatus
      }
    });

    return data?.changeOrganizationPlan;
  }
}

const Api = new DecidedApi();

export default Api;

export { DecidedApi, ErrorTypes };
