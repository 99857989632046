import PropTypes from "prop-types";

import Check from "../icons/Check";

import { Colors, getColors } from "../utils";

function Checkbox({
  name,
  half,
  color = Colors.DEFAULT,
  label,
  value,
  checked,
  disabled,
  required,
  labelUpper,
  ...restProps
}) {
  const getComposeBorder = (border) => {
    switch (border) {
      case "border-primary-red":
        return "hover:border-primary-red";
      case "border-primary-blue":
        return "hover:border-primary-blue";
      case "border-primary-green":
        return "hover:border-primary-green";
      case "border-primary-orange":
        return "hover:border-primary-orange";

      default:
        return "hover:border-black";
    }
  };

  const colors = getColors(color);
  const border = getComposeBorder(colors.primary.border);

  return (
    <label
      className={`
        py-2
        flex
        flex-1
        flex-row
        items-center
        ${disabled ? "opacity-40 cursor-not-allowed" : "cursor-pointer"}
      `}
    >
      <div
        className={`
          w-5
          h-5
          mr-2
          flex
          border
          relative
          bg-white
          rounded-sm
          items-center
          flex-shrink-0
          justify-center
          ${!disabled ? border : ""}
          ${value ? colors.primary.border : "border-black"}
        `}
      >
        <input
          id={name}
          name={name}
          value={value}
          type="checkbox"
          aria-label={name}
          checked={checked}
          className={`
            sr-only
            text-sm
            absolute
            font-medium
            outline-none
            ${disabled ? "pointer-events-none" : ""}
          `}
          {...restProps}
        />
        {value && <Check color={`fill-current ${colors.primary.text}`} />}
        {half && !value && (
          <span
            className={`w-2 h-1 border-b-2 rounded-full ${colors.primary.border}`}
          ></span>
        )}
      </div>
      <span
        className={`
          ml-2
          flex
          text-sm
          flex-row
          normal-case
          font-semibold
          ${labelUpper ? "uppercase" : "capitalize"}
        `}
      >
        {label}
        {required && (
          <div className={`px-1 font-bold ${colors.primary.text}`}>*</div>
        )}
      </span>
    </label>
  );
}

Checkbox.propTypes = {
  half: PropTypes.bool,
  value: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  labelUpper: PropTypes.bool,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default Checkbox;
