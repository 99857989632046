import PropTypes from "prop-types";

function ArrowLeft({ size = 32, className = "", ...restProps }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
      {...restProps}
    >
      <path d="M353,450a15,15,0,0,1-10.61-4.39L157.5,260.71a15,15,0,0,1,0-21.21L342.39,54.6a15,15,0,1,1,21.22,21.21L189.32,250.1,363.61,424.39A15,15,0,0,1,353,450Z" />
    </svg>
  );
}

ArrowLeft.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string
};

export default ArrowLeft;
