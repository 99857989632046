const RESET = "USERS/RESET";
const SET_PAGE = "USERS/SET_PAGE";
const SET_ERROR = "USERS/SET_ERROR";
const SET_IS_LOADING = "USERS/SET_IS_LOADING";

const Types = {
  RESET,
  SET_PAGE,
  SET_ERROR,
  SET_IS_LOADING
};

export default Types;
