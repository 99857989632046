import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import App from "./App";
import store from "./redux/store";
import { getRedirectUri } from "./core/utils";
import reportWebVitals from "./reportWebVitals";
import { ModalContextProvider } from "./contexts/ModalContext";
import { SpinnerContextProvider } from "./contexts/SpinnerContext";
import { installUnexpectedErrorToast } from "./utils/unexpectedErrorToast";

import "./styles/index.css";

const sentryConfig = {
  dsn: window.SERVER_DATA.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: window.SERVER_DATA.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
};
if (window.SERVER_DATA.ENV === "master") {
  sentryConfig.replaysSessionSampleRate = 0.1;
  sentryConfig.replaysOnErrorSampleRate = 0.2;
  sentryConfig.integrations.push(Sentry.replayIntegration());
}
Sentry.init(sentryConfig);

TagManager.initialize({
  gtmId: window.SERVER_DATA.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  linker: {
    accept_incoming: true
  }
});

installUnexpectedErrorToast();

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <ModalContextProvider>
          <SpinnerContextProvider>
            <Auth0Provider
              useRefreshTokens={true}
              domain={window.SERVER_DATA.REACT_APP_AUTH0_DOMAIN}
              authorizationParams={{ redirect_uri: getRedirectUri() }}
              clientId={window.SERVER_DATA.REACT_APP_AUTH0_CLIENT_ID}
            >
              <App />
            </Auth0Provider>
          </SpinnerContextProvider>
        </ModalContextProvider>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
