import { Suspense, useState, useEffect, useContext, lazy } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import { Route, Navigate, useLocation, useNavigate } from "react-router-dom";

import Loader from "./components/Common/Loader";
import Layout from "./components/Home/Common/Layout";
import PrivateRoute from "./components/Common/PrivateRoute";
import CustomSwitch from "./components/Common/CustomSwitch";
import ModalContext from "./contexts/ModalContext";
import Invitation from "./components/Invitation";

import { useUser } from "./redux/User/hooks";
import { setOrganizationId } from "./redux/User/actions";
import { removeState, Items, loadState } from "./redux/storePersist";

import { PageViewDataLayer } from "./utils/DataLayers";

import "./styles/tailwind.output.css";
import "react-toastify/dist/ReactToastify.css";

function SignUp() {
  const navigate = useNavigate();
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const redirectToSignUp = async () => {
      if (!isLoading) {
        if (!isAuthenticated)
          await loginWithRedirect({
            authorizationParams: { screen_hint: "signup" }
          });
        else navigate("/");
      }
    };

    redirectToSignUp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  return null;
}

function Logout() {
  const { logout } = useAuth0();
  useEffect(() => {
    removeState(Items.APP_USER);
    removeState(Items.APP_STATE);
    logout({ logoutParams: { returnTo: window.location.origin } });
  });
  return null;
}

function ForceLogin() {
  const { logout } = useAuth0();
  useEffect(() => {
    removeState(Items.GTM_STATE);
    logout({ logoutParams: { returnTo: window.location.origin } });
  });
  return null;
}

function Authenticate() {
  const location = useLocation();
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const qs = new URLSearchParams(location.search.slice(1));
    setRedirect(qs.get("next") || "/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (redirect) return <Navigate to={redirect} replace />;
  return null;
}

const HomeRoutesLazy = lazy(() => import("./components/Home"));
const CreateAccountLazy = lazy(() => import("./components/CreateAccount"));
const APIAssociationsRoutes = lazy(() =>
  import("./components/APIAssociations/")
);

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const modalContext = useContext(ModalContext);

  const { isLoading, isAuthenticated } = useAuth0();
  const { profile, organizationId, hasOrganizations } = useUser();

  useEffect(() => {
    const pagePath = location.pathname + location.search;
    PageViewDataLayer({
      event: "pageview",
      pagePath: location.pathname,
      screenName: pagePath
    });
  }, [location]);

  useEffect(() => {
    if (profile && !hasOrganizations) {
      if (!location.pathname.startsWith("/api-associations" || "/invitation")) {
        navigate("/api-associations");
      }
    }

    const savedOrgId = loadState(Items.APP_ORG_ID);

    const isSavedOrgIdValid = profile?.organizationUsers?.some(
      (organizationUser) => organizationUser.organization.id === savedOrgId
    );

    if (savedOrgId && isSavedOrgIdValid) {
      dispatch(setOrganizationId({ organizationId: savedOrgId }));
    } else if (profile?.organizationUsers?.length > 0) {
      dispatch(
        setOrganizationId({
          organizationId: profile?.organizationUsers[0]?.organization?.id
        })
      );
    }
  }, [
    dispatch,
    hasOrganizations,
    location.pathname,
    navigate,
    organizationId,
    profile
  ]);

  if (!isLoading && !isAuthenticated) {
    removeState(Items.APP_USER);
    removeState(Items.APP_STATE);
  }

  return (
    <Suspense fallback={<Loader />}>
      <Layout>
        <CustomSwitch>
          <Route path="logout" element={<Logout />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="force-login" element={<ForceLogin />} />
          <Route path="create-account" element={<CreateAccountLazy />} />
          <Route
            path="/invitation/:invitationId"
            element={
              <PrivateRoute>
                <Invitation />
              </PrivateRoute>
            }
          />
          <Route
            path="/api-associations/*"
            element={<APIAssociationsRoutes />}
          />
          <Route
            path="authenticate"
            element={
              <PrivateRoute>
                <Authenticate />
              </PrivateRoute>
            }
          />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <HomeRoutesLazy
                  openModal={modalContext.openModalHandler}
                  modalResponse={modalContext.modalResponse}
                />
              </PrivateRoute>
            }
          />
        </CustomSwitch>
        <ToastContainer />
      </Layout>
    </Suspense>
  );
}

export default App;
