import PropTypes from "prop-types";

function CollegePublic({
  size = 25,
  iconColor = "fill-black",
  fillColor = "fill-white",
  strokeColor = "stroke-black"
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 62 63"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="red"
        strokeWidth="2"
        strokeMiterlimit="10"
        className={`${fillColor} ${strokeColor}`}
        d="M24.9919 46.3258V62H37.109V46.3258C37.109 46.3258 34.6048 44.582 31.0504 42.6353C28.3847 44.0953 24.9919 46.3258 24.9919 46.3258ZM61 28.4211V62H1V28.4211H12.5315V12.0372L30.9899 2L49.4685 12.0372V28.4819L61 28.4211Z"
      />
      <path
        className={iconColor}
        d="M27.7768 13.3145H21.375V19.7423H27.7768V13.3145Z"
      />
      <path
        className={iconColor}
        d="M40.7437 13.3145H34.3418V19.7423H40.7437V13.3145Z"
      />
      <path
        className={iconColor}
        d="M27.7768 24.2036H21.375V30.6315H27.7768V24.2036Z"
      />
      <path
        className={iconColor}
        d="M40.7437 24.2036H34.3418V30.6315H40.7437V24.2036Z"
      />
      <path
        className={iconColor}
        d="M14.146 35.1733H7.74414V41.6012H14.146V35.1733Z"
      />
      <path
        className={iconColor}
        d="M54.2749 35.1733H47.873V41.6012H54.2749V35.1733Z"
      />
    </svg>
  );
}

CollegePublic.propTypes = {
  size: PropTypes.number,
  iconColor: PropTypes.string,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string
};

export default CollegePublic;
