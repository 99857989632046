import PropTypes from "prop-types";

function Logo({ width = 50, height = 67, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      viewBox="0 0 185 202"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M23.5582 26.3394L1.32568 2.01878V119.732L21.2882 141.886C21.2969 141.895 21.7214 142.32 21.7214 142.32H75.1974C112.982 142.32 135.544 119.879 135.544 82.302C135.544 66.1518 131.186 52.3236 122.886 41.9351C122.834 41.8485 122.028 40.9214 122.028 40.9214L119.766 38.4174L103.096 19.9192L102.377 19.0874C91.8241 6.60219 76.1591 0.00866699 57.0544 0.00866699H3.00656L25.2911 24.39H76.679C93.2884 24.39 107.325 29.6405 117.28 39.5785C117.358 39.6564 117.427 39.7431 117.505 39.8211C117.635 39.9597 117.774 40.107 117.904 40.2456C119.888 42.299 121.681 44.5344 123.284 46.9344C123.336 47.0124 123.397 47.0991 123.449 47.177C123.821 47.7489 124.185 48.3207 124.54 48.9099C124.592 48.9879 124.636 49.0745 124.688 49.1525C126.152 51.6131 127.426 54.2298 128.5 56.985C128.561 57.1496 128.63 57.3143 128.691 57.4789C128.907 58.042 129.107 58.6052 129.306 59.1771C129.392 59.437 129.488 59.6969 129.574 59.9655C129.756 60.5027 129.93 61.0485 130.086 61.6031C130.181 61.9063 130.259 62.2182 130.346 62.5301C130.493 63.0673 130.649 63.5959 130.787 64.1417C130.874 64.4796 130.943 64.8262 131.03 65.1641C131.238 66.0478 131.429 66.9316 131.602 67.8414C131.697 68.3179 131.792 68.7944 131.87 69.2796C131.948 69.7215 132.009 70.172 132.078 70.6226C132.156 71.1251 132.226 71.619 132.295 72.1302C132.356 72.5721 132.399 73.0139 132.451 73.4645C132.512 73.993 132.564 74.5302 132.616 75.0674C132.65 75.5006 132.694 75.9251 132.72 76.367C132.763 76.9562 132.798 77.554 132.824 78.1605C132.841 78.5504 132.867 78.9403 132.884 79.3389C132.91 80.1793 132.928 81.0284 132.936 81.8775C132.936 82.0248 132.945 82.1721 132.945 82.328C132.945 100.28 127.616 114.992 117.54 124.861C107.602 134.6 92.9592 139.746 75.1974 139.746H23.5582V138.646"
      />
      <path
        fill="transparent"
        d="M132.936 81.8515C132.936 80.9938 132.919 80.1533 132.884 79.3129C132.867 78.9143 132.85 78.5245 132.824 78.1346C132.798 77.5367 132.763 76.9389 132.72 76.3411C132.685 75.9078 132.65 75.4746 132.616 75.0414C132.564 74.5042 132.512 73.967 132.451 73.4385C132.399 72.988 132.356 72.5461 132.295 72.1042C132.226 71.6017 132.156 71.0992 132.079 70.5966C132.009 70.1461 131.949 69.6955 131.871 69.2537C131.784 68.7685 131.689 68.2919 131.602 67.8154C131.429 66.9143 131.238 66.0219 131.03 65.1381C130.952 64.8002 130.874 64.4537 130.788 64.1157C130.649 63.5699 130.502 63.0327 130.346 62.5042C130.259 62.1923 130.172 61.889 130.086 61.5771C129.921 61.0226 129.748 60.4854 129.575 59.9396C129.488 59.6796 129.401 59.4197 129.306 59.1511C129.107 58.5793 128.907 58.0074 128.691 57.4529C128.63 57.2883 128.561 57.1237 128.491 56.9591C127.408 54.2038 126.143 51.5872 124.679 49.1265C124.627 49.0486 124.584 48.9619 124.532 48.8839C124.177 48.2948 123.813 47.7229 123.44 47.1511C123.388 47.0731 123.328 46.9865 123.276 46.9085C122.886 46.328 122.487 45.7475 122.08 45.1843C121.777 44.7771 121.473 44.3698 121.161 43.9626C120.858 43.5727 120.538 43.1915 120.226 42.8103C120.174 42.741 120.113 42.6803 120.061 42.611L117.904 40.211C117.765 40.0724 117.635 39.9251 117.505 39.7864C117.427 39.7085 117.358 39.6218 117.28 39.5438C107.325 29.6059 93.2885 24.3553 76.6791 24.3553H23.5583V139.686H24.2428H26.6168H75.1975C92.9593 139.686 107.602 134.539 117.54 124.8C127.616 114.932 132.945 100.22 132.945 82.2674C132.945 82.1461 132.936 81.9988 132.936 81.8515Z"
      />
      <path
        fill="transparent"
        d="M3.00656 0H1.32568V2.01878L23.5582 26.3394V24.3813H25.2911L3.00656 0Z"
      />
      <path
        className={color}
        d="M184.359 108.962L171.648 96.5028H140.431L141.748 97.7938L143.065 99.0848L155.801 111.561H181.759V139.712H153.886V113.476L138.499 98.4089V129.661L151.287 142.311H153.886H181.759H184.359V139.712V111.561V108.962Z"
      />
      <path
        fill="transparent"
        d="M155.801 111.561L143.065 99.0848L140.431 96.5028H138.499V98.4089L153.886 113.476V139.712H181.759V111.561H155.801Z"
      />
      <path
        className={color}
        d="M0 170.193H14.2961C23.2203 170.193 29.4152 175.911 29.4152 185.745C29.4152 195.622 23.5235 201.167 13.9062 201.167H0V170.193ZM13.1697 193.673C17.3286 193.673 20.2311 190.987 20.2311 185.745C20.2311 180.633 17.5019 177.687 13.1697 177.687H9.05418V193.673H13.1697Z"
      />
      <path
        className={color}
        d="M30.6282 190.077C30.6282 183.406 35.4369 178.337 42.4116 178.337C45.8773 178.337 48.5199 179.463 50.556 181.326C53.242 183.795 54.4983 187.738 54.4116 192.113H38.6426C39.1192 194.669 40.5055 196.229 42.9748 196.229C44.4044 196.229 45.4441 195.665 46.0506 194.582H53.9784C53.5019 196.575 51.9423 198.525 49.7762 199.911C47.7401 201.211 45.5307 201.774 42.7149 201.774C35.4802 201.774 30.6282 196.835 30.6282 190.077ZM38.6426 187.781H46.2672C45.9206 185.312 44.5344 183.839 42.5849 183.839C40.3322 183.839 39.0759 185.355 38.6426 187.781Z"
      />
      <path
        className={color}
        d="M55.5381 190.164C55.5381 183.449 60.3901 178.424 67.6681 178.424C73.9064 178.424 78.0652 182.106 78.6284 187.261H70.7439C70.3107 185.268 69.3576 184.142 67.5814 184.142C65.0688 184.142 63.8558 186.438 63.8558 190.164C63.8558 193.846 65.1554 196.099 67.6681 196.099C69.6609 196.099 70.6573 194.756 70.8739 192.243H78.7584C78.7584 197.702 74.2963 201.86 67.6681 201.86C60.3901 201.86 55.5381 196.879 55.5381 190.164Z"
      />
      <path
        className={color}
        d="M80.6646 170.193H88.8523V176.387H80.6646V170.193ZM80.6646 179.073H88.8523V201.167H80.6646V179.073Z"
      />
      <path
        className={color}
        d="M90.8884 190.077C90.8884 183.146 94.874 178.38 100.766 178.38C103.928 178.38 105.488 179.55 106.917 181.413H107.047V170.193H115.235V201.167H107.437V198.091H107.351C105.921 200.561 103.582 201.904 100.852 201.904C95.1339 201.904 90.8884 197.702 90.8884 190.077ZM107.264 189.947C107.264 186.785 105.618 184.272 103.192 184.272C100.809 184.272 99.2061 186.568 99.2061 189.947C99.2061 193.326 100.766 195.709 103.192 195.709C105.618 195.709 107.264 193.153 107.264 189.947Z"
      />
      <path
        className={color}
        d="M118.571 170.193H142.701V177.557H127.625V181.759H140.232V188.561H127.625V193.413H143.134V201.167H118.571V170.193Z"
      />
      <path
        className={color}
        d="M146.167 170.193H160.463C169.387 170.193 175.582 175.911 175.582 185.745C175.582 195.622 169.69 201.167 160.073 201.167H146.167V170.193ZM159.336 193.673C163.495 193.673 166.398 190.987 166.398 185.745C166.398 180.633 163.668 177.687 159.336 177.687H155.221V193.673H159.336Z"
      />
      <path
        className={color}
        d="M176.362 192.893H184.809V201.167H176.362V192.893Z"
      />
    </svg>
  );
}

Logo.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default Logo;
