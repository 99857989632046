import { useState, useCallback, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";

import UserProfile from "./UserProfile";
import { SimpleSelect } from "../../../core/inputs";
import SideMenu from "../../Common/SideMenu";

import Logo from "../../Common/Icons/Logo";
import Profile from "../../Common/Icons/Profile";

import { useUser } from "../../../redux/User/hooks";
import { setOrganizationId } from "../../../redux/User/actions";
import { fetchOrganization } from "../../../redux/Organization/actions";
import { Items, loadState, saveState } from "../../../redux/storePersist";

function Header() {
  const dispatch = useDispatch();
  const { profile, organizationId, hasOrganizations } = useUser();

  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [openUserProfile, setOpenUserProfile] = useState(false);

  useEffect(() => {
    if (organizationId) {
      const orgId = String(organizationId);
      const orgUser = profile?.organizationUsers.find(
        (organization) => organization?.organization.id === orgId
      );

      if (orgUser) {
        setCurrentOrganization(orgUser.organization);
      }
    }
  }, [organizationId, profile]);

  const organizations = useMemo(
    () =>
      profile?.organizationUsers.map(
        (organization) => organization.organization
      ) || [],
    [profile]
  );

  const handleUserProfileOpen = useCallback(() => {
    setOpenUserProfile(!openUserProfile);
  }, [openUserProfile]);

  useEffect(() => {
    const savedOrgId = loadState(Items.APP_ORG_ID);

    if (savedOrgId && organizationId !== savedOrgId) {
      dispatch(setOrganizationId({ organizationId: savedOrgId }));
    }
  }, [dispatch, organizationId]);

  const handleOnSelectOrganization = useCallback(
    (organization) => {
      dispatch(setOrganizationId({ organizationId: organization.id }));
      dispatch(fetchOrganization({ organizationId: organization.id }));
      saveState(organization.id, Items.APP_ORG_ID);
    },
    [dispatch]
  );

  return (
    <>
      <div className="p-4 z-20 top-0 flex sticky flex-row bg-white shadow-md">
        <div className="ml-5 flex flex-1">
          <Logo />
        </div>
        <div className="flex items-center justify-end">
          {hasOrganizations && (
            <div className="w-72 mr-4">
              <SimpleSelect
                list={organizations}
                title="your organization"
                current={currentOrganization?.name}
                onSelect={handleOnSelectOrganization}
              />
            </div>
          )}
        </div>
        <Profile
          id="button-header-profile"
          onClick={handleUserProfileOpen}
          className="flex items-end justify-end cursor-pointer place-self-center"
        />
      </div>

      <SideMenu open={openUserProfile} direction="rtl">
        <UserProfile onClose={handleUserProfileOpen} />
      </SideMenu>
    </>
  );
}

export default Header;
