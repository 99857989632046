import OkayHand from "./Icons/OkayHand";

function NotFound() {
  return (
    <div
      className="
        p-4
        flex
        flex-1
        h-full
        w-full
        flex-col
        min-h-screen
        items-center
        overflow-auto
        justify-center
        bg-primary-blue
      "
    >
      <OkayHand />
      <div
        className="
          mb-5
          text-6xl
          text-center
          max-w-25rem
          text-white
          leading-none
          font-extrabold
        "
      >
        <span>Page Not Found!</span>
      </div>
      <div
        className="
          flex
          text-xl
          flex-row
          font-bold
          underline
          text-white
          items-center
          justify-center
        "
      >
        <a href="/">Go Home</a>
      </div>
    </div>
  );
}

export default NotFound;
