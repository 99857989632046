const Items = {
  APP_USER: "state:user",
  APP_STATE: "state:app",
  APP_ORG_ID: "state:orgId"
};

const initializeState = (item) => {
  switch (item) {
    case Items.APP_USER:
      return {
        error: null,
        profile: null,
        organizationId: null,
        isLoading: false,
        isLoggedIn: false
      };
    case Items.APP_STATE:
      return {
        currentTab: 0,
        internalState: {}
      };
    case Items.APP_ORG_ID:
      return null;
    default:
      return {};
  }
};

const loadState = (item) => {
  try {
    const serializedState = localStorage.getItem(item);

    if (serializedState === null) {
      return initializeState(item);
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return initializeState(item);
  }
};

const saveState = (state, item) => {
  try {
    localStorage.setItem(item, JSON.stringify(state));
  } catch (err) {
    console.error(err);
  }
};

const removeState = (item) => {
  try {
    localStorage.removeItem(item);
  } catch (err) {
    console.error(err);
  }
};

export { loadState, saveState, removeState, Items };
