import PropTypes from "prop-types";
import ReactModal from "react-modal";

import { InviteUsersModal } from "../Modals/index";

ReactModal.setAppElement("#modal-root");

const MODAL_COMPONENTS = {
  inviteUsers: {
    title: "Invite Users to your Org Account",
    component: InviteUsersModal
  }
};

const getModal = (modalData) => {
  const modal = MODAL_COMPONENTS[modalData.type];
  return {
    title: modal ? modal.title : "Modal Title",
    component: modal ? (
      <modal.component data={modalData.data} />
    ) : (
      <div>Modal component</div>
    )
  };
};

function Modal({ show, closeModal, modalData, clearResponse }) {
  const onRequestCloseHandle = () => {
    closeModal();
  };

  const onAfterClose = () => {
    clearResponse("");
  };

  const modal = getModal(modalData);

  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onRequestCloseHandle}
      onAfterClose={onAfterClose}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      className="
        p-6
        w-1/2
        h-2/3
        mx-auto
        my-auto
        absolute
        left-0
        right-0
        top-0
        bottom-0
        bg-white
        outline-none
      "
      overlayClassName="
        z-30
        fixed
        inset-0
        bg-transparent-primary-blue
      "
    >
      <div className="flex flex-col h-full">
        <div className="flex flex-row">
          <p className="font-bold text-3xl">{modal.title}</p>
        </div>
        {modal.component}
      </div>
    </ReactModal>
  );
}

Modal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  clearResponse: PropTypes.func,
  modalData: PropTypes.shape({
    data: PropTypes.any,
    type: PropTypes.string,
    permissions: PropTypes.shape()
  })
};

export default Modal;
