import PropTypes from "prop-types";

function CollegeCity({
  size = 25,
  iconColor = "fill-black",
  fillColor = "fill-white",
  strokeColor = "stroke-black"
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 60 61"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="2"
        strokeMiterlimit="10"
        className={`${fillColor} ${strokeColor}`}
        d="M0 59.9998H60M23.7307 6.92285H10.3003V22.4118H23.7307V6.92285ZM25.9121 22.3727H8.09988V42.9855H25.9121V22.3727ZM53.6453 19.6543H35.6434V59.9802H53.6453V19.6543ZM27.6573 43.1224H6.37372V59.8629H27.6573V43.1224Z"
      />
      <path
        className={iconColor}
        d="M49.4733 24.3872H45.8691V28.103H49.4733V24.3872Z"
      />
      <path
        className={iconColor}
        d="M49.4733 37.0601H45.8691V40.7758H49.4733V37.0601Z"
      />
      <path
        className={iconColor}
        d="M49.4733 49.7134H45.8691V53.4292H49.4733V49.7134Z"
      />
      <path
        className={iconColor}
        d="M43.4186 24.3872H39.8145V28.103H43.4186V24.3872Z"
      />
      <path
        className={iconColor}
        d="M49.4733 30.7236H45.8691V34.4394H49.4733V30.7236Z"
      />
      <path
        className={iconColor}
        d="M43.4186 30.7236H39.8145V34.4394H43.4186V30.7236Z"
      />
      <path
        className={iconColor}
        d="M43.4186 37.0601H39.8145V40.7758H43.4186V37.0601Z"
      />
      <path
        className={iconColor}
        d="M49.4733 43.377H45.8691V47.0927H49.4733V43.377Z"
      />
      <path
        className={iconColor}
        d="M43.4186 43.377H39.8145V47.0927H43.4186V43.377Z"
      />
      <path
        className={iconColor}
        d="M43.4186 49.7134H39.8145V53.4292H43.4186V49.7134Z"
      />
      <path
        strokeWidth="2"
        strokeMiterlimit="10"
        className={`${fillColor} ${strokeColor}`}
        d="M16.958 6.98174V0V6.98174ZM19.2343 51.4929H14.6816V59.8436H19.2343V51.4929Z"
      />
      <path
        className={iconColor}
        d="M15.8581 26.2061H12.2539V29.9218H15.8581V26.2061Z"
      />
      <path
        className={iconColor}
        d="M15.8581 35.7109H12.2539V39.4267H15.8581V35.7109Z"
      />
      <path
        className={iconColor}
        d="M21.7956 26.2061H18.1914V29.9218H21.7956V26.2061Z"
      />
      <path
        className={iconColor}
        d="M15.8581 30.9585H12.2539V34.6743H15.8581V30.9585Z"
      />
      <path
        className={iconColor}
        d="M21.7956 30.9585H18.1914V34.6743H21.7956V30.9585Z"
      />
      <path
        className={iconColor}
        d="M21.7956 35.7109H18.1914V39.4267H21.7956V35.7109Z"
      />
    </svg>
  );
}

CollegeCity.propTypes = {
  size: PropTypes.number,
  iconColor: PropTypes.string,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string
};

export default CollegeCity;
