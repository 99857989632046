import PropTypes from "prop-types";

function Box({ children, className = "" }) {
  return <div className={`border border-black ${className}`}>{children}</div>;
}

Box.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Box;
