const RESET = "WEBHOOKS/RESET";
const SET_PAGE = "WEBHOOKS/SET_PAGE";
const SET_ERROR = "WEBHOOKS/SET_ERROR";
const SET_IS_LOADING = "WEBHOOKS/SET_IS_LOADING";

const Types = {
  RESET,
  SET_PAGE,
  SET_ERROR,
  SET_IS_LOADING
};

export default Types;
