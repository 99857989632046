const RESET = "API_KEYS/RESET";
const SET_PAGE = "API_KEYS/SET_PAGE";
const SET_ERROR = "API_KEYS/SET_ERROR";
const SET_IS_LOADING = "API_KEYS/SET_IS_LOADING";

const Types = {
  RESET,
  SET_PAGE,
  SET_ERROR,
  SET_IS_LOADING
};

export default Types;
