import PropTypes from "prop-types";

function Button({
  children,
  disabled = false,
  className = `
    bg-white
    text-black
    border-white
    hover:bg-light-gray
    active:bg-light-gray
    hover:border-light-gray
    active:border-light-gray
  `,
  ...restProps
}) {
  return (
    <button
      tabIndex="0"
      className={`
      py-2
        px-5
        border
        capitalize
        font-extrabold
        ${className}
        ${disabled ? "opacity-40 cursor-not-allowed pointer-events-none" : ""}
      `}
      disabled={disabled}
      {...restProps}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default Button;
