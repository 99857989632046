import PropTypes from "prop-types";

import { ShortArrow } from "../icons";

function BackButton({ disabled = false, ...restProps }) {
  return (
    <button
      tabIndex="0"
      className={`
        flex
        flex-row
        capitalize
        items-center
        cursor-pointer
        font-extrabold
        ${disabled ? "opacity-40 cursor-not-allowed pointer-events-none" : ""}
      `}
      disabled={disabled}
      {...restProps}
    >
      <ShortArrow />
      <p className="font-black">Back</p>
    </button>
  );
}

BackButton.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default BackButton;
