import PropTypes from "prop-types";

import { CloseMini } from "./icons";
import { Colors, getColors } from "./utils";

function Pill({ item, color = Colors.DEFAULT, onRemovePill, pillWidthFull }) {
  const colors = getColors(color);

  return (
    <div
      className={`
        py-1
        my-1
        mx-1
        pl-4
        pr-8
        flex
        border
        relative
        flex-row
        space-x-2
        rounded-lg
        min-w-6rem
        border-black
        ${colors.secondary.bg}
        ${pillWidthFull ? "max-w-full" : "max-w-10rem"}
      `}
    >
      <p
        className="
          text-sm
          truncate
          font-medium
          text-center
          mobile:text-xs
          mobile-sm:text-xs
          tablet-sm:text-xs
        "
      >
        {item.label}
      </p>
      <div
        onClick={() => onRemovePill(item)}
        className="right-2 absolute cursor-pointer"
      >
        <CloseMini
          size={14}
          fillColor="fill-white"
          strokeColor="stroke-black"
        />
      </div>
    </div>
  );
}

Pill.propTypes = {
  item: PropTypes.shape(),
  onRemovePill: PropTypes.func,
  pillWidthFull: PropTypes.bool,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default Pill;
