import PropTypes from "prop-types";

function SideMenu({ open, children, direction = "ltr" }) {
  const getSideMenuPosition = () => {
    switch (direction) {
      case "rtl":
        return open ? "right-0" : "-mr-5 -right-0 translate-x-full";

      case "ltr":
      default:
        return open ? "left-0" : "-ml-5 -left-0 -translate-x-full";
    }
  };

  const position = getSideMenuPosition();

  return (
    <div
      className={`
        z-30
        fixed
        h-full
        w-full
        max-w-sm
        inset-y-0
        shadow-lg
        bg-white
        transition
        duration-500
        overflow-scroll
        ${position}
      `}
    >
      {children}
    </div>
  );
}

SideMenu.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.element,
  direction: PropTypes.oneOf(["rtl", "ltr", "ttb", "btt"])
};

export default SideMenu;
