import React, { useState } from "react";

import { useUser } from "../../../redux/User/hooks";

import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

function Layout({ children }) {
  const { isLoggedIn, hasOrganizations } = useUser();

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  return (
    <div className="flex flex-col min-h-screen">
      {isLoggedIn && <Header />}
      <div className="flex flex-row flex-1 relative">
        {isLoggedIn && hasOrganizations ? (
          <>
            <button
              onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
              className={`
                absolute
                top-2
                z-10
                transform
                transition-transform
                duration-300
                -translate-x-1/2
                rounded-full
                h-8
                w-8
                bg-white
                flex
                items-center
                justify-center
                border
                border-gray-300
                shadow-md
                hover:shadow-lg
                hover:bg-primary-blue
                hover:text-white
                ${sidebarCollapsed ? "left-6" : "left-[14rem]"}`}
            >
              {sidebarCollapsed ? ">" : "<"}
            </button>

            <div
              className={`
              flex
              flex-col
              transition-all
              duration-300
              sticky
              top-24
              max-h-screen-footer-sm
              overflow-y-auto 
              ${sidebarCollapsed ? "w-0 hidden" : "w-56"}`}
            >
              <Sidebar />
            </div>
            <div
              className={`p-4 flex flex-1 flex-col bg-white min-h-screen-header ${
                sidebarCollapsed ? "w-full" : "w-5/6"
              }`}
            >
              {children}
            </div>
          </>
        ) : (
          <div className="flex flex-1 flex-col bg-grey-100 min-h-screen-header">
            {children}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
