import PropTypes from "prop-types";

import ErrorEntry from "../icons/ErrorEntry";
import CorrectEntry from "../icons/CorrectEntry";

import { Colors, getColors } from "../utils";

function SimpleTextarea({
  name,
  color = Colors.DEFAULT,
  label,
  value,
  errors,
  subLabel,
  disabled,
  required,
  labelUpper,
  placeholder,
  ...restProps
}) {
  const colors = getColors(color);

  return (
    <div className="py-2">
      <div
        className={`
          flex
          flex-1
          text-sm
          flex-col
          capitalize
          ${disabled && "opacity-40"}
        `}
      >
        <span
          className={`
            flex
            flex-row
            font-semibold
            ${colors.primary.text}
            ${labelUpper && "uppercase"}
          `}
        >
          {label}
          {required && <div className="px-1 font-bold">*</div>}
        </span>
        {subLabel && <span className="text-black font-medium">{subLabel}</span>}
      </div>
      <div
        className={`
          mt-2
          flex
          border
          flex-row
          relative
          min-h-3rem
          border-black
          ${
            disabled
              ? "opacity-40 cursor-not-allowed pointer-events-none"
              : "focus-within:shadow-black"
          }
        `}
      >
        <textarea
          id={name}
          name={name}
          value={value}
          aria-label={name}
          autoCapitalize="none"
          placeholder={placeholder}
          className={`
            p-2
            w-full
            text-sm
            font-medium
            text-black
            outline-none
            ${disabled ? "pointer-events-none" : ""}
          `}
          {...restProps}
        />
        {!disabled &&
          (value || (errors && errors[name])) &&
          (errors && errors[name] ? (
            <div className="absolute top-0 right-0">
              <ErrorEntry size={17} fillColor="fill-primary-red" />
            </div>
          ) : (
            <div className="absolute top-0 right-0">
              <CorrectEntry
                size={17}
                fillColor={`fill-current ${colors.primary.text}`}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

SimpleTextarea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  errors: PropTypes.shape(),
  subLabel: PropTypes.string,
  labelUpper: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default SimpleTextarea;
