import PropTypes from "prop-types";

function ShortArrow({ size = 25, color = "stroke-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="2"
        className={color}
        strokeLinejoin="round"
        d="M22 3L9 15.5L22 28"
      />
    </svg>
  );
}

ShortArrow.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default ShortArrow;
