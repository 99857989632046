import PropTypes from "prop-types";

function CollegeForprofit({
  size = 25,
  iconColor = "fill-black",
  fillColor = "fill-white",
  strokeColor = "stroke-black"
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 50 61"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="2"
        strokeMiterlimit="10"
        className={`${fillColor} ${strokeColor}`}
        d="M20.0372 59.4925H29.652V44.3997H20.0372V59.4925ZM48.6091 27.2842V59.4925H1V27.2842L24.7965 2L48.6091 27.2842Z"
      />
      <path
        className={iconColor}
        d="M22.2477 17.0732H17.168V23.2387H22.2477V17.0732Z"
      />
      <path
        className={iconColor}
        d="M32.5369 17.0732H27.457V23.2387H32.5369V17.0732Z"
      />
      <path
        className={iconColor}
        d="M22.0564 28.8794H16.9766V35.0448H22.0564V28.8794Z"
      />
      <path
        className={iconColor}
        d="M32.6658 28.8794H27.5859V35.0448H32.6658V28.8794Z"
      />
      <path
        className={iconColor}
        d="M11.4313 28.7817H6.35156V34.9472H11.4313V28.7817Z"
      />
      <path
        className={iconColor}
        d="M43.2731 28.7817H38.1934V34.9472H43.2731V28.7817Z"
      />
    </svg>
  );
}

CollegeForprofit.propTypes = {
  size: PropTypes.number,
  iconColor: PropTypes.string,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string
};

export default CollegeForprofit;
