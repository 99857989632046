const RESET = "JOB/RESET";
const SET_JOB = "JOB/SET_JOB";
const SET_PAGE = "JOB/SET_PAGE";
const SET_ERROR = "JOB/SET_ERROR";
const RESET_PAGE = "JOB/RESET_PAGE";
const SET_IS_LOADING = "JOB/SET_IS_LOADING";

const Types = {
  RESET,
  SET_JOB,
  SET_PAGE,
  SET_ERROR,
  RESET_PAGE,
  SET_IS_LOADING
};

export default Types;
