import PropTypes from "prop-types";

function CorrectEntry({
  size = 25,
  fillColor = "fill-black",
  iconColor = "stroke-white"
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.5"
        y="1.5"
        width="13"
        height="13"
        className={`stroke-black ${fillColor}`}
      />
      <path
        strokeWidth="2"
        className={iconColor}
        d="M12 4.65234L6.70298 10.1258L4 7.61448"
      />
    </svg>
  );
}

CorrectEntry.propTypes = {
  size: PropTypes.number,
  fillColor: PropTypes.string,
  iconColor: PropTypes.string
};

export default CorrectEntry;
