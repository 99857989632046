import { useNavigate } from "react-router-dom";

import Box from "../../Common/Box";
import Button from "../../Common/Button";
import BackButton from "../../Common/BackButton";
import EditPencil from "../../Common/Icons/EditPencil";

import { useUser } from "../../../redux/User/hooks";

function InfoRow({ id, title, value, className, capitalize = true }) {
  return (
    <div key={`${title}-${value}`} className={`my-2 ${className}`}>
      <p className="my-1 text-sm capitalize font-semibold text-primary-blue">
        {title}
      </p>
      <p
        id={`info-row-${id}`}
        className={`font-medium ${capitalize && "capitalize"}`}
      >
        {value}
      </p>
    </div>
  );
}

function InfoHeader({ title, onClick, isEditable }) {
  return (
    <div className="flex flex-1 flex-row items-center">
      <p className="flex flex-1 font-semibold text-xl">{title}</p>
      {isEditable && (
        <div
          className="flex flex-row items-center cursor-pointer"
          onClick={onClick}
        >
          <EditPencil />
          <p className="ml-1 text-xs font-semibold">Edit</p>
        </div>
      )}
    </div>
  );
}

function UserProfile({ onClose }) {
  const navigate = useNavigate();

  const user = useUser();

  const logOut = () => {
    navigate("/logout");
  };

  return (
    <div
      id="profile-drawer"
      className="shadow-md bg-white h-full w-full overflow-auto"
    >
      <div className="p-4 flex flex-1 flex-row items-center">
        <BackButton handlerOnClick={() => onClose()} />
        <div className="flex flex-1" />
      </div>
      <div className="px-4 laptop:px-6 desktop:px-6">
        <h1 className="p-2 text-3xl font-bold">Your Account</h1>
        <Box
          className="
            p-4
            mb-6 
            flex
            flex-1
            flex-col
            bg-white
          "
        >
          <InfoHeader title="Account Info" onClick={null} isEditable={false} />
          <InfoRow
            id="name"
            title="name"
            className="capitalize"
            value={`${user.profile?.firstName} ${user.profile?.lastName}`}
          />
          <InfoRow
            id="email"
            title="email"
            capitalize={false}
            value={user.profile?.email}
          />
        </Box>
      </div>
      <div
        className="
          py-4
          mb-6
          flex
          flex-1
          flex-row
          space-x-4
          items-center
          justify-center
        "
      >
        <Button
          onClick={logOut}
          id="sign-out-button"
          className="
            w-1/2
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
          "
        >
          sign out
        </Button>
      </div>
    </div>
  );
}

export default UserProfile;
