import { createContext, useState } from "react";
import SyncLoader from "react-spinners/SyncLoader";

const SpinnerContext = createContext();

export default SpinnerContext;

export function SpinnerContextProvider({ children }) {
  const [spinnerCount, setSpinnerCount] = useState(0);

  const showSpinnerHandler = (showSpinner) => {
    if (showSpinner) {
      setSpinnerCount(spinnerCount + 1);
    } else {
      setSpinnerCount(Math.max(0, spinnerCount - 1));
    }
  };

  return (
    <SpinnerContext.Provider value={showSpinnerHandler}>
      {spinnerCount > 0 && (
        <>
          <div
            style={{ zIndex: 80 }}
            className="
              flex
              fixed
              top-0
              flex-1
              left-0
              flex-col
              w-screen
              h-screen
              bg-black
              opacity-25
              items-center
              justify-center
            "
          />
          <div
            style={{ zIndex: 81 }}
            className="
              flex
              fixed
              top-0
              flex-1
              left-0
              flex-col
              w-screen
              h-screen
              opacity-100
              items-center
              justify-center
            "
          >
            <SyncLoader size={55} color="#3661F5" loading={true} />
          </div>
        </>
      )}
      {children}
    </SpinnerContext.Provider>
  );
}
