import PropTypes from "prop-types";

import { Pill } from "../";
import { Colors } from "../utils";

function PillList({ color, data, onRemovePill, pillWidthFull }) {
  return (
    <div className="flex flex-row flex-wrap">
      {data.map((item, index) => (
        <Pill
          key={index}
          item={item}
          color={color}
          onRemovePill={onRemovePill}
          pillWidthFull={pillWidthFull}
        />
      ))}
    </div>
  );
}

PillList.propTypes = {
  onRemovePill: PropTypes.func,
  pillWidthFull: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape()),
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT,
  ]),
};

export default PillList;
