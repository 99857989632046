import PropTypes from "prop-types";

import ArrowLeft from "./Icons/ArrowLeft";

function BackButton({
  handlerOnClick,
  buttonClassName = "",
  textClassName = "text-black font-semibold place-self-center"
}) {
  return (
    <div className="flex flex-row items-end">
      <button
        onClick={handlerOnClick}
        className={`
          flex
          flex-row
          capitalize
          items-center
          cursor-pointer
          font-extrabold
          ${buttonClassName}
        `}
      >
        <ArrowLeft size={20} className="stroke-current text-black" />
        <span className={textClassName}>Back</span>
      </button>
    </div>
  );
}

BackButton.propTypes = {
  handlerOnClick: PropTypes.func,
  buttonClassName: PropTypes.string,
  textClassName: PropTypes.string
};

export default BackButton;
