import Types from "./types";

import Api from "../../services/Api";
import { Items, loadState } from "../storePersist";

function setError(error) {
  return {
    type: Types.SET_ERROR,
    error
  };
}

function setActiveTab(activeTab) {
  return {
    type: Types.SET_ACTIVE_TAB,
    activeTab
  };
}
function setIsLoading(isLoading) {
  return {
    type: Types.SET_IS_LOADING,
    isLoading
  };
}

function setIsLoggedIn(isLoggedIn) {
  return {
    type: Types.SET_IS_LOGGED_IN,
    isLoggedIn
  };
}

function setCurrentOrganizationId(organizationId) {
  return {
    type: Types.SET_CURRENT_ORGANIZATION_ID,
    organizationId
  };
}

function setProfile(profile) {
  return {
    type: Types.SET_PROFILE,
    profile
  };
}

function setSchemaTypes(schemaTypes) {
  return {
    type: Types.SET_SCHEMA_TYPES,
    schemaTypes
  };
}

function setHasOrganizations(hasOrganizations) {
  return {
    type: Types.SET_HAS_ORGANIZATIONS,
    hasOrganizations
  };
}

export function fetchUser() {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(setError(null));

      const { user, __schema } = await Api.fetchUser();

      dispatch(setIsLoggedIn(true));
      dispatch(setProfile(user));
      dispatch(setSchemaTypes(__schema?.types));
      dispatch(setHasOrganizations(user?.organizationUsers?.length > 0));

      const currentOrgId = loadState(Items.APP_ORG_ID) || getState()?.user?.organizationId;

      if (!currentOrgId && user?.organizationUsers?.length > 0) {
        dispatch(
          setCurrentOrganizationId(user.organizationUsers[0].organization.id)
        );
      } else if (currentOrgId) {
        dispatch(setCurrentOrganizationId(currentOrgId));
      }
    } catch (error) {
      dispatch(setError(error));
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function setCurrentActiveTab({ activeTab }) {
  return (dispatch) => {
    dispatch(setActiveTab(activeTab));
  };
}

export function setOrganizationId({ organizationId }) {
  return (dispatch) => {
    dispatch(setCurrentOrganizationId(organizationId));
  };
}
